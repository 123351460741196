import { SET_CAMPAIGNS, SET_PROFILE, SET_INTERACTIONS } from './actions';

const initializeLocalStorageState = {
    campaigns: [],
    profile: {},
    interactions: [],
}


export function localStorage(state = initializeLocalStorageState, action){
    switch(action.type){
        case SET_CAMPAIGNS:
            return {...state, campaigns: action.payload}
        case SET_PROFILE:
            return {...state, profile: action.payload}
        case SET_INTERACTIONS:
            return {...state, interactions: action.payload}
        default:
            return state;
    }
}