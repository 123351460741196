import React from "react";
import Preloader from "../Components/Preloader/Preloader";
import Timer from "../Components/Countdown/Timer";
import "../styles/ComingSoon.css";
import "../styles/styles.css";

function ComingSoon() {
  return (
    <div className="comingSoon">
      <div className="overlay-container">
          <div className="App">
          <div className="comingSoonContainer">
              <h1>
              Swapt Mobile App
              <br />
              Coming Soon
              </h1>
              <Timer />
              <Preloader />
          </div>
          </div>
      </div>
    </div>
  );
}

export default ComingSoon;
