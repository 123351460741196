import { getCookie } from "../Pages/Home";

//when true, pinging server will ping the local server. When false, it will ping real API.
const local_server = false;
//paste your local ip address here so expo can connect to local functions emulator
export const local_ip_address = "http://192.168.1.214";



const ENDPOINT = local_server ? local_ip_address + ":8080/" : 'https://olsonify-backend-iw423pkgkq-uc.a.run.app/'; 

export const newClient = async (email, password) => {
    const data = {
        email: email,
        password: password
    }
    const backend_query = await fetch(`${ENDPOINT}newClient`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }

}

export const signInClient = async (email, password) => {
    const data = {
        email: email,
        password: password
    }
    const backend_query = await fetch(`${ENDPOINT}signInClient`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        return response;
    }else{
        return { message: 'nr' };
    }

}

export const verifyAccount = async (username) => {
  const backend_query = await fetch(`${ENDPOINT}newClient?username=${username}`, {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Accept-encoding': 'gzip, deflate',
          'Content-Type': 'application/json',
      }
  });
  if(backend_query.ok){
      const response = await backend_query.json();
      console.log(response);
      return response;
  }else{
      return { message: 'nr' };
  }

}

export const getCampaigns = async (id) => {
    const body = {
        id: id
    }
    const backend_query = await fetch(`${ENDPOINT}getCampaigns`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const getAllCampaignInteractions = async (id) => {
    const body = {
        id: id
    }
    const backend_query = await fetch(`${ENDPOINT}getAllCampaignInteractions`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const getCampaignInteractions = async (id) => {
    const body = {
        id: id
    }
    const backend_query = await fetch(`${ENDPOINT}getCampaignInteractions`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const pauseCampaign = async (id) => {
    const body = {
        id: id
    }
    const backend_query = await fetch(`${ENDPOINT}pauseCampaign`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const deleteCampaign = async (id) => {
    const body = {
        id: id
    }
    const backend_query = await fetch(`${ENDPOINT}deleteCampaign`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const initializeCampaign = async (body) => {
    const backend_query = await fetch(`${ENDPOINT}initializeCampaign`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const createCampaignPayment = async (token, email, amount, userID) => {
    const body = {
        token: token,
        email: email,
        amount: amount,
        userID: userID
    }
    const backend_query = await fetch(`${ENDPOINT}createCampaignPayment`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const addCard = async (card, profile) => {
    const body = {
        card: card,
        profile: profile
    }
    const backend_query = await fetch(`${ENDPOINT}addCard`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const removePaymentMethod = async (card, profile) => {
    const body = {
        card: card,
        profile: profile
    }
    const backend_query = await fetch(`${ENDPOINT}removePaymentMethod`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const createCustomer = async (profile, card) => {
    const body = {
        profile: profile,
        card: card
    }
    const backend_query = await fetch(`${ENDPOINT}createCustomer`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const getProfile = async (id, token) => {
    const body = {
        id: id
    }
    const backend_query = await fetch(`${ENDPOINT}getClientProfile`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
            Auth: token
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const publishNewCampaign = async (token, campaign, payAhead, email, cardToken, userProfile) => {
    const body = {
        campaign: campaign,
        payAhead: payAhead,
        email: email,
        userProfile: userProfile,
        cardToken: cardToken
    }
    const backend_query = await fetch(`${ENDPOINT}publishNewCampaign`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
            Auth: token
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const resumeCampaign = async (token, campaign, payAhead, email, cardToken, userProfile) => {
    const body = {
        campaign: campaign,
        payAhead: payAhead,
        email: email,
        userProfile: userProfile,
        cardToken: cardToken
    }
    const backend_query = await fetch(`${ENDPOINT}resumeCampaign`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
            Auth: token
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const changeDefaultPayment = async (i, profile) => {
    const token = getCookie("token");
    const body = {
        i: i,
        profile: profile
    }
    const backend_query = await fetch(`${ENDPOINT}changeDefaultPayment`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
            Auth: token
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const deleteAccount = async (id) => {
    const token = getCookie("token");
    const body = {
        id: id
    }
    const backend_query = await fetch(`${ENDPOINT}deleteClientAccount`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
            Auth: token
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const changeClientEmail = async (id, email) => {
    const token = getCookie("token");
    const body = {
        id: id,
        email: email
    }
    const backend_query = await fetch(`${ENDPOINT}changeClientEmail`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
            Auth: token
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const changeClientPassword = async (id, password) => {
    const token = getCookie("token");
    const body = {
        id: id,
        password: password
    }
    const backend_query = await fetch(`${ENDPOINT}changeClientPassword`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
            Auth: token
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const hash = async (password) => {
    const token = getCookie("token");
    const body = {
        password: password
    }
    const backend_query = await fetch(`${ENDPOINT}hash`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
            Auth: token
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}

export const mongoQuery = async (query, db, collection, operation) => {
    const token = getCookie("token");
    const body = {
        query: query,
        db: db,
        collection: collection,
        operation: operation
    }
    const backend_query = await fetch(`${ENDPOINT}mongoQuery`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
            Auth: token
        },
        body: JSON.stringify(body)
    });
    if(backend_query.ok){
        const response = await backend_query.json();
        console.log(response);
        return response;
    }else{
        return { message: 'nr' };
    }
  
}