import {React } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ComingSoon from './Pages/ComingSoon';
import { SwaptBusinessLandingPage } from './Pages/LandingPages/SwaptBusinessLandingPage/pages/entryPoint.tsx';
import { SwaptLandingPage } from './Pages/LandingPages/SwaptLandingPage/pages/entryPoint.tsx';
import { PrivacyPolicy } from './Pages/PrivacyPolicy';
import { Home } from './Pages/Home';
import AccountVerified from './Pages/AccountVerified';
import { TermsAndConditions } from './Pages/TermsAndConditions.js';
import MongoQueries from './Pages/MongoQueries';
import { Store } from './redux/store';
import { Provider } from 'react-redux';
import { Global, css } from '@emotion/react';

const GlobalStyles = () => (
  <Global
      styles={css`
    /* Apply global styles */
    body {
      font-family: 'Quicksand';
      background-color: "#222222";
    }
  `}
  />
);

export default function App() {
  return (
    <Provider store={Store} style={{ backgroundColor: '#222222' }}>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route index path='/swapt' element={<SwaptLandingPage />} />
          <Route path='/swapt/business' element={<SwaptBusinessLandingPage />} />
          <Route path='/swapt/business/query' element={<MongoQueries />} />
          <Route path='/swapt/business/*' element={<Home />} />
          <Route path='/swapt/privacy' element={<PrivacyPolicy />} />
          <Route path='/swapt/termsandconditions' element={<TermsAndConditions />} />
          <Route path='/swapt/account-verified' element={<AccountVerified />} />
          <Route
              path="*"
              element={<Navigate to="/swapt" replace />}
          />
          
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
