import React from 'react';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LinkIcon from '@mui/icons-material/Link';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import config from '../config/index.json';

const Features = () => {
  const { features } = config;
  const { title, subtitle, description, items: featuresList } = features;
  return (
    <div className={`py-12 bg-background`} id="features">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2
            className={`text-base text-primary font-semibold tracking-wide uppercase`}
          >
            {title}
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            {subtitle}
          </p>
          <p className="mt-4 max-w-2xl text-xl text-[#A1A1A1] lg:mx-auto">
            {description}
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {featuresList.map((feature, i) => (
              <div key={feature.name} className="relative">
                <dt>
                <div
                    className={`absolute flex items-center justify-center h-12 w-12 rounded-md bg-background text-tertiary`}
                  >
                    {i === 0 ? (
                      <TrendingUpIcon fontSize="large" />
                    ) : i === 1 ? (
                      <QrCodeScannerIcon fontSize="large" />
                    ) : i === 2 ? (
                      <LinkIcon fontSize="large" />
                    ) : (
                      <QueryStatsIcon fontSize="large" />
                    )}
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-white">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-[#A1A1A1]">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Features;
