import React from 'react';
import { useRef, useState, useEffect } from 'react';
import "../../../../styles/LandingPage.css";
import config from '../config/index.json';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { newClient, signInClient } from '../../../../backend/database';
import { useNavigate } from 'react-router-dom';
import { FaArrowRightLong, FaArrowLeftLong } from 'react-icons/fa6';

const MainHero = () => {
  const { mainHero } = config;
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [getStarted, setGetStarted] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [getStartedLoading, setGetStartedLoading] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("token="))
    ?.split("=")[1];
    if (cookieValue !== undefined){
      navigate('/swapt/business/home');
    }else{
      setReadyToLoad(true);
    }
  },[]);

  const getStartedPressed = async () => {
    setErrorMessage("");
    if (getStarted) {
      if(email !== "" && password !== ""){
        setGetStartedLoading(true);
        newClient(email, password).then((response) => {
          if (response.message !== "success"){
            setErrorMessage(response.message);
          }else{
            setErrorMessage("Email verification sent. Please verify and then return to sign in.");
          }
          setGetStartedLoading(false);
        });
      }else{
        setErrorMessage("please fill out all fields.");
      }

    }else{
      setSignIn(false);
      setGetStarted(true);
    }
  }

  const signInPressed = () => {
    setErrorMessage("");
    if (signIn) {
      if(email !== "" && password !== ""){
        setGetStartedLoading(true);
        signInClient(email, password).then((response) => {
          if (response.message !== undefined){
            setErrorMessage(response.message);
          }else{
            document.cookie = `token=${response.token}; max-age=${30*24*60*60}; path=/; `;
            document.cookie = `id=${response.id}; max-age=${30*24*60*60}; path=/; `;
            navigate("/swapt/business/home");
          }
          setGetStartedLoading(false);
        });
      }else{
        setErrorMessage("please fill out all fields.");
      }

    }else{
      setSignIn(true);
      setGetStarted(false);
    }
  }

  return readyToLoad ? (
    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
      <div className="sm:text-center lg:text-left">
        <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
          <span className="block xl:inline">{mainHero.title}</span>{' '}
        </h1>
        <h3 className="text-2xl tracking-tight font-extrabold text-white sm:text-3xl md:text-4xl">
          <span className={`block text-primary xl:inline`}>
            {mainHero.subtitle}
          </span>
        </h3>
        <p className="mt-3 text-base text-tertiary sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          {mainHero.description}
        </p>
        {errorMessage !== "" && (
          <h3 className={`${errorMessage === "Email verification sent. Please verify and then return to sign in." ? "text-success" : "text-error"} mt-5`}>{errorMessage}</h3>
        )}
        <div className={`absolute mt-5 flex flex-col transition-300 ${getStarted || signIn ? 'opacity-100 lg:items-start md:items-center lg:left-8 md:left-0 lg:w-9/12 md:w-full' : "opacity-0 w-12 -left-10"}`}>
          <input type="email" onChange={(event) => {setEmail(event?.target.value); setErrorMessage("");}} className="lg:w-4/5 md:w-2/5 h-10 rounded-lg p-2" placeholder="Email" >
              
          </input><br />
          <input type="password" onChange={(event) => {setPassword(event?.target.value); setErrorMessage("");}} className="lg:w-4/5 md:w-2/5 h-10 rounded-lg p-2" placeholder="Password" >
          
          </input>
        </div>
        <div className={`flex sm:justify-center lg:justify-start transition-300 ${getStarted || signIn ? 'mt-40' : 'mt-4'}`}>
          <div className={`flex lg:flex-row md:flex-col sm:flex-row xs:flex-col items-center`}>
            <button
              onClick={() => {if(signIn){signInPressed()}else{getStartedPressed()}}}
              className={`flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background bg-primary hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
            >
              {getStartedLoading ? (
                <Spinner />
              ) : (
                (!signIn && !getStarted) ? mainHero.primaryAction.text : <div style={{ whiteSpace: 'pre-wrap' }}className="flex flex-row items-center justify-center">{signIn ? "Sign In    " : "Register    "}<FaArrowRightLong/></div>
              )}
              
            </button>
            <button 
              onClick={() => {if(signIn){getStartedPressed()}else{signInPressed()}}}
              className={`w-[80px] md:mt-5 lg:mt-0 ml-0 md:ml-0 lg:ml-5 text-secondary flex items-center justify-center`}
            >
              {signInLoading ? (
                <Spinner />
              ) : (
                signIn ? "Register" : "Sign in"
              )}
            </button>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <div />
  );
};

export default MainHero;
