import React from 'react';
import { useRef, useState, useEffect } from 'react';
import "../../../../styles/LandingPage.css";
import config from '../config/index.json';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { newClient, signInClient } from '../../../../backend/database';
import { useNavigate } from 'react-router-dom';
import { FaArrowRightLong, FaArrowLeftLong } from 'react-icons/fa6';
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";

const MainHero = () => {
  const { mainHero } = config;

  const navigate = useNavigate();

  return (
    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
      <div className="sm:text-center lg:text-left">
        <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
          <span className="block xl:inline">{mainHero.title}</span>{' '}
        </h1>
        <h3 className="text-2xl tracking-tight font-extrabold text-white sm:text-3xl md:text-4xl">
          <span className={`block text-primary xl:inline`}>
            {mainHero.subtitle}
          </span>
        </h3>
        <p className="mt-3 text-base text-tertiary sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          {mainHero.description}
        </p>
        <div className={`flex sm:justify-center lg:justify-start transition-300 mt-10`}>
          <div className={`w-full md:w-auto lg:w-auto sm:w-auto p-6 flex lg:flex-row md:flex-row sm:flex-row xs:flex-col flex-col items-center rounded-lg drop-shadow-lg bg-lighterBackground`}>
            <button onClick={() => {window.location.href = "https://itunes.apple.com/us/app/swapt-community-trading/id6476014581"}} className="lg:mr-6 md:mr-6 sm:mr-6 xs:mr-0 mr-0 pl-4 pr-4 pt-1 pb-1 bg-background rounded-lg flex flex-row items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
              <path d="M22.6667 6.6387C23.7612 5.22345 24.5899 3.22144 24.29 1.17773C22.501 1.30191 20.4104 2.4499 19.1919 3.94488C18.0805 5.30121 17.1663 7.31552 17.5225 9.27302C19.477 9.3342 21.4967 8.15878 22.6667 6.6387Z" fill="white"/>
              <path d="M31.3594 12.4655C29.6415 10.3057 27.228 9.05225 24.9482 9.05225C21.939 9.05225 20.6661 10.4973 18.5753 10.4973C16.4196 10.4973 14.7815 9.05661 12.1792 9.05661C9.62293 9.05661 6.90085 10.6234 5.17475 13.3021C2.74862 17.0746 3.16402 24.1672 7.09597 30.2084C8.50292 32.3702 10.382 34.8009 12.84 34.822C15.0269 34.8427 15.6434 33.415 18.6069 33.4004C21.5699 33.3836 22.1322 34.8411 24.3151 34.8178C26.7751 34.7987 28.7569 32.1048 30.164 29.9434C31.1726 28.3937 31.5483 27.6138 32.3301 25.864C26.6407 23.692 25.7286 15.5795 31.3594 12.4655Z" fill="white"/>
            </svg>
              <div className=" ml-4 flex flex-col justify-between items-start h-full">
                <div className="text-white text-xs">Download on the</div>
                <div className="text-white text-lg">App Store</div>
              </div>
            </button>
            <button onClick={() => {window.location.href = "https://play.google.com/store/apps/details?id=com.olsonify.swapt&pcampaignid=web_share"}} className="relative lg:mt-0 md:mt-0 sm:mt-0 xs:mt-6 mt-6 pl-4 pr-4 pt-1 pb-1 bg-background rounded-lg flex flex-row items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M19.7277 17.3005L3.496 33.576C2.72018 32.8402 2.3009 31.8309 2.3009 30.7372V5.20985C2.3009 4.09546 2.74127 3.08592 3.53819 2.32928L19.7277 17.3005Z" fill="#2299F8"/>
                <path d="M33.7573 17.9733C33.7573 19.4455 32.96 20.749 31.6387 21.4854L27.0259 24.0505L21.3005 18.7513L19.7278 17.3005L25.7882 11.2234L31.6387 14.4617C32.96 15.1982 33.7573 16.5016 33.7573 17.9733Z" fill="#FFC107"/>
                <path d="M19.7277 17.3005L3.53821 2.32923C3.74781 2.11857 4.02069 1.9295 4.29329 1.76131C5.61426 0.962352 7.20817 0.940977 8.57104 1.69838L25.7882 11.2234L19.7277 17.3005Z" fill="#5ACF5F"/>
                <path d="M27.0258 24.0506L8.57099 34.2489C7.92109 34.6276 7.18667 34.7955 6.47385 34.7955C5.71911 34.7955 4.96431 34.6063 4.29325 34.186C3.9992 34.0177 3.72667 33.8074 3.49597 33.576L19.7277 17.3004L21.3004 18.7513L27.0258 24.0506Z" fill="#F84437"/>
              </svg>
              <div className=" ml-4 flex flex-col justify-between items-start h-full">
                <div className="text-white text-xs">GET IT ON</div>
                <div className="text-white text-lg font-bold">Google Play</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </main>
  )
};

export default MainHero;
