import React from "react";
import { useState, useEffect, useRef } from 'react';
import "../styles/styles.css";
import { Spinner } from "react-activity";
import { getCampaigns, initializeCampaign, createCampaignPayment, publishNewCampaign, getCampaignInteractions, pauseCampaign, deleteCampaign, resumeCampaign, createCustomer, addCard } from "../backend/database";
import { getCookie } from "../Pages/Home";
import { setCampaigns, setProfile } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyExchangeTwoTone } from "@mui/icons-material";
import {APIProvider, AdvancedMarker, Map, Marker} from '@vis.gl/react-google-maps';
import { Circle } from './circle.tsx';
import { FaLock } from "react-icons/fa";
import { FaArrowRightLong, FaArrowLeftLong } from 'react-icons/fa6';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import { Form, Field } from "react-final-form";
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
  } from "./cardUtils";
import Styles from './Styles.js';
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "../backend/firebaseConfig";
import { Statistics } from './Statistics';
import { IoIosClose } from "react-icons/io";

const publicTestKey = "pk_test_51OygOqEY0IOq0W591k71UJxTdIvZXfhQrIekZZQegtNG4UEAwvC0dFlYLZV1z3e8zznVlR2TVuxPn8UwviZuxIgu00IhDVQwcp";
const publicLiveKey = "pk_live_51OygOqEY0IOq0W59INKZjBYG6AajPqPMpNVbo71V6ENfUtFS3BG8vga7bPM93pXdj3nv752ugesRhLsEvCfoljDl00Jr5a3ZaV"

const stripePromise = loadStripe(publicLiveKey);

export const Campaigns = () => {

    const [createCampaign, setCreateCampaign] = useState(0);
    const [title, setTitle] = useState(false);
    const [currentCampaign, setCurrentCampaign] = useState({ title: "", description: "" });
    const [location, setLocation] = useState({ lat: 31, lng: -97 });
    const [center, setCenter] = useState({ lat: 31, lng: -97 });
    const [locationLoading, setLocationLoading] = useState(false);
    const [payAhead, setPayAhead] = useState(2);
    const [defaultPayment, setDefaultPayment] = useState(true);
    const [publishingCampaign, setPublishingCampaign] = useState(false);
    const [hasChangedLocation, setHasChangedLocation] = useState(0);
    const [securityInfoShowing, setSecurityInfoShowing] = useState(0);
    const [campaignInteractions, setCampaignInteractions] = useState([]);
    const [deletingCampaign, setDeletingCampaign] = useState();
    const dispatch = useDispatch();

    const localStorage = useSelector(state => state.localStorage);

    const [clientSecret, setClientSecret] = useState("");

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const dealPromotionRef = useRef();
    const dealCodeRef = useRef();
    const externalWebsiteRef = useRef();
    const externalWebsiteNameRef = useRef();

    const cardNumberRef = useRef();
    const cvcRef = useRef();
    const expirationRef = useRef();
    const cardNameRef = useRef();

    useEffect(() => {
        if (createCampaign === 3 && !window.document.getElementById("stripe-script")) {
            var s = window.document.createElement("script");
            s.id = "stripe-script";
            s.type = "text/javascript";
            s.src = "https://js.stripe.com/v2/";
            s.onload = () => {
                window["Stripe"].setPublishableKey(
                "pk_test_51OygOqEY0IOq0W591k71UJxTdIvZXfhQrIekZZQegtNG4UEAwvC0dFlYLZV1z3e8zznVlR2TVuxPn8UwviZuxIgu00IhDVQwcp"
                );
            };
            window.document.body.appendChild(s);
        }
    }, [createCampaign]);

    useEffect(() => {
        setHasChangedLocation(hasChangedLocation+1);
    },[location]);


    const inputRef = useRef();
    const markerRef = useRef();

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    
    const newCampaign = () => {
        setTitle(true);
    }

    useEffect(() => {
        if (localStorage.campaigns.length === 0) {
            const idValue = getCookie("id");
            getCampaigns(idValue).then((response) => {
                if (response.campaigns !== undefined) {
                    dispatch(setCampaigns(response.campaigns));
                }else{
                    dispatch(setCampaigns({error: "true", message: response.message}));
                }
            })
        }
    },[])

    const viewCampaign = (campaign) => {
        if (campaign.status === 'incomplete' || campaign.status === 'paused') {
            setCurrentCampaign(campaign);
            setCreateCampaign(1);
            setLocation({ lat: campaign.location.coordinates[1], lng: campaign.location.coordinates[0] });
            setCenter({ lat: campaign.location.coordinates[1], lng: campaign.location.coordinates[0] });
        }else {
            setCurrentCampaign(campaign);
            getCampaignInteractions(campaign._id).then(async (response) => {
                if (response.interactions !== undefined) {
                    setCampaignInteractions(response.interactions);
                    setCreateCampaign(4);
                }else {
                    alert(response.message);
                }
            })
        }
    }

    const startCampaign = () => {
        setTitle(true);
        let campaign = currentCampaign;
        campaign.owner = {
            id: getCookie("id"),
            username: "",
            profilePic: ""
        };
        campaign.status = "incomplete";
        campaign.type = "advertisement";
        campaign.pics = [];
        campaign.package = "";
        campaign.start = [];
        campaign.end = [];
        campaign.paidUntil = "";
        campaign.payAhead = "";
        campaign.location = {
            type: "Point",
            coordinates: [-90, 32]
        }

        setCenter({lng: -90, lat: 32});
        setLocation({ lat: 32, lng: -90});

        initializeCampaign(campaign).then((response) => {
            if (response.message === 'initialized successfuly') {
                dispatch(setCampaigns([...localStorage.campaigns, response.campaign]));
                setCurrentCampaign(response.campaign);
            }
        });
        setCreateCampaign(1);
        setTitle(false);
    }

    const getCurrentLocation = () => {
        setLocationLoading(true);
        navigator.geolocation.getCurrentPosition((position) => {
            setLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
            setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
            setCurrentCampaign({...currentCampaign, location: { type: "Point", coordinates: [position.coords.longitude, position.coords.latitude] }});
            setLocationLoading(false);
        })
    }

    const changeCenter = (newCenter) => {
        if (!newCenter) return;
        setCenter({lng: newCenter.lng(), lat: newCenter.lat()});
      };

    const publishingNewCampaign = (newCampaign, card) => {
        const token = getCookie("token");
        if (newCampaign.status === 'paused') {
            resumeCampaign(token, newCampaign, payAhead, localStorage.profile.email, card, localStorage.profile).then((response) => {
                if (response.message !== 'success') {
                    alert(response.message);
                    setPublishingCampaign(false);
                }else {
                    setPublishingCampaign(false);
                    let newCampaigns = [];
                    localStorage.campaigns.map((x) => {
                        if (x._id === currentCampaign._id) {
                            let activeCampaign = currentCampaign;
                            let today = new Date();
                            today.setHours(0,0,0,0);
                            activeCampaign.status = 'active';
                            activeCampaign.startDateTime = new Date();
                            activeCampaign.paidUntil = today;
                            newCampaigns.push(activeCampaign);
                        }else {
                            newCampaigns.push(x);
                        }
                    });
                    dispatch(setCampaigns(newCampaigns));
                    setCurrentCampaign({});
                    setCreateCampaign(0);
                }
            });
        }else {
            publishNewCampaign(token, newCampaign, payAhead, localStorage.profile.email, card, localStorage.profile).then((response) => {
                if (response.message !== 'success') {
                    alert(response.message);
                    setPublishingCampaign(false);
                }else {
                    setPublishingCampaign(false);
                    let newCampaigns = [];
                    localStorage.campaigns.map((x) => {
                        if (x._id === currentCampaign._id) {
                            let activeCampaign = currentCampaign;
                            let today = new Date();
                            today.setHours(0,0,0,0);
                            activeCampaign.status = 'active';
                            activeCampaign.startDateTime = new Date();
                            activeCampaign.paidUntil = new Date(today.getTime() - (24 * 60 * 60 * 1000));
                            newCampaigns.push(activeCampaign);
                        }else {
                            newCampaigns.push(x);
                        }
                    });
                    dispatch(setCampaigns(newCampaigns));
                    setCurrentCampaign({});
                    setCreateCampaign(0);
                }
            });
        }

    }

    const uploadCampaign = async (newCard) => {
        let uploadPics = [];
        const userID = getCookie("id");
        currentCampaign.pics.map(async (image, index) => {
            if (image.name !== undefined) {
                let randomString = '_';
                for(let i = 0; i < 3; i++){
                    randomString += Math.floor(Math.random() * 10).toString();
                }
                
                //create the storage url for firebase storage
                const storageUrl = userID + '/' + currentCampaign.title + randomString +  '/' + index;
              
                const storageRef = ref(storage, storageUrl);
    
                const img = await fetch(URL.createObjectURL(image));
                const blob = await img.blob();
    
                const uploadTask = uploadBytesResumable(storageRef, blob);
    
    
    
                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on('state_changed',(snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                    break;
                    case 'running':
                        console.log('Upload is running');
                    break;
                    }
                },
                (error) => {
                    this.setState({ isLoading: false })
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                    case 'storage/unauthorized':
                        console.log("User doesn't have permission to access the object");
                    break;
                    case 'storage/canceled':
                        console.log("User canceled the upload");
                    break;
                    case 'storage/unknown':
                        console.log("Unknown error occurred, inspect error.serverResponse");
                    break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    console.log('upload was successful');
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        uploadPics.push(downloadURL);
                        if (uploadPics.length === currentCampaign.pics.length) {
                            let newCampaign = currentCampaign;
                            newCampaign.pics = uploadPics;
                            console.log(newCampaign);
                            setCurrentCampaign(newCampaign);
                            publishingNewCampaign(newCampaign, newCard !== undefined ? newCard : localStorage.profile.cards[0]);
                        }
                    });
                });
            }else {
                uploadPics.push(image);
                if (uploadPics.length === currentCampaign.pics.length) {
                    let newCampaign = currentCampaign;
                    newCampaign.pics = uploadPics;
                    console.log(newCampaign);
                    setCurrentCampaign(newCampaign);
                    publishingNewCampaign(newCampaign, newCard !== undefined ? newCard : localStorage.profile.cards[0]);
                }
            }
          
        });
    }

    const publishCampaign = () => {
        setPublishingCampaign(true);
        if (localStorage.profile.cards.length === 0 || !defaultPayment) {
            window.Stripe.card.createToken(
                {
                  number: cardNumberRef.current.value,
                  exp_month: expirationRef.current.value.split("/")[0],
                  exp_year: expirationRef.current.value.split("/")[1],
                  cvc: cvcRef.current.value,
                  name: cardNameRef.current.value,
                },
                (status, response) => {
                  if (status === 200) {
                    if (localStorage.profile.customerid === undefined) {
                        createCustomer(localStorage.profile, response).then((res) => {
                            if (res.message === "success") {
                                dispatch(setProfile({...localStorage.profile, customerid: res.customerid, cards: [response]}));
                                uploadCampaign(undefined);
                            }else {
                                alert(res.message);
                            }
                        })
                    }else {
                        addCard(localStorage.profile, response).then((res) => {
                            if (res.message === "success") {
                                dispatch(setProfile({...localStorage.profile, cards: [...localStorage.profile.cards, response]}));
                                uploadCampaign(response);
                            }else {
                                alert(res.message);
                            }
                        })
                    }
                  } else {
                    console.log(response.error.message);
                    setPublishingCampaign(false);
                  }
                }
              );
        } else {
            uploadCampaign(undefined);
        }
    }

    const nextPage = (pageNum) => {
        setCreateCampaign(pageNum);
        let newCampaigns = [];
        localStorage.campaigns.map((x) => {
            if (x._id === currentCampaign._id) {
                newCampaigns.push(currentCampaign);
            }else {
                newCampaigns.push(x);
            }
        });
        dispatch(setCampaigns(newCampaigns));
    }

    const setPackage = (x) => {
        if (x === 'basic') {
            setCurrentCampaign({...currentCampaign, package: "basic", pics: currentCampaign.pics.filter((x,i) => i <= 0), deal: {promotion: null, code: null}, external_website: {website: null, name: null}}); 
            dealPromotionRef.current.value = "";
            dealCodeRef.current.value = "";
            externalWebsiteNameRef.current.value = "";
            externalWebsiteRef.current.value = "";
        }else {
            setCurrentCampaign({...currentCampaign, package: "standard", pics: currentCampaign.pics.filter((x,i) => i <= 2), external_website: {website: null, name: null}});
            externalWebsiteNameRef.current.value = "";
            externalWebsiteRef.current.value = "";
        }
    }

    const pauseCampaignPressed = () => {
        setSecurityInfoShowing(0);
        pauseCampaign(currentCampaign._id).then(async (response) => {
            if (response.message === 'success') {
                dispatch(setCampaigns([...localStorage.campaigns.filter((x) => x._id !== currentCampaign._id), response.campaign]));
                setCreateCampaign(0);
            }else {
                alert(response.message);
            }
        })
    }

    const deleteCampaignPressed = () => {
        setSecurityInfoShowing(0);
        deleteCampaign(deletingCampaign._id).then(async (response) => {
            if (response.message === 'success') {
                deletingCampaign.pics.map(async (x) => {
                    console.log('deleting');
                    let imageRef = ref(storage, x);
                    deleteObject(imageRef).then(() => {
                        console.log('file deleted');
                    }).catch((error) => {
                        console.log(`error occured: ${error}`);
                    }); 
                });
                dispatch(setCampaigns([...localStorage.campaigns.filter((x) => x._id !== deletingCampaign._id)]));
                setCreateCampaign(0);
                setDeletingCampaign(undefined);

            }else {
                alert(response.message);
            }
        })
    }

    return (
        <div className={`w-full h-screen ${title ? "overflow-y-hidden" : "overflow-y-auto" } pt-5 relative`}>
            {securityInfoShowing > 0 && (
                <div className="w-full h-full flex items-center justify-center absolute top-0 z-20">
                    <div className=" p-6 bg-[#3a3939] z-10 absolute bottom-10 w-[500px] h-[500px] rounded-xl drop-shadow-2xl flex justify-center items-center flex-col relative">
                            <button onClick={() => {setSecurityInfoShowing(0)}} className="absolute top-2 right-2 text-md w-10 h-10 text-white"><IoIosClose size="medium"/></button>
                            {securityInfoShowing === 1 ? (
                                <div className="text-lg text-white text-center w-3/5">When customers swipe right on your promotion, they receive a custom QR code that reflects the deal you input here. They can then come to your place of business and get their QR code scanned to activate the deal. When you scan their QR code, input the security code you set here to mark that customer's idividual promotion as complete.</div>
                            ) : securityInfoShowing === 2 ? (
                                <div className="text-lg text-white text-center w-3/5">Are you sure? By pausing this campaign, you will be automatically billed for the current outstanding cost of this campaign, if any. You can resume at any time.</div>
                            ) : (
                                <div className="text-lg text-white text-center w-3/5">Are you sure? You cannot restart this campaign once you delete it.</div>
                            )}
                            {(securityInfoShowing === 2 || securityInfoShowing === 3) && (
                                <button onClick={() => {securityInfoShowing === 2 ? pauseCampaignPressed() : deleteCampaignPressed()}} className="mb-5 bg-yellow-700 p-3 text-md text-background mt-10 rounded-md">Confirm</button>
                            )}
                            
                    </div> 
                </div>
            )}
            {createCampaign == 0 ? (
                <div className="">
                    {title && (
                        <div  className={`top-0 right-0 transition-300 bg-black/40 h-[120%] w-[100%] z-100  absolute ${!title ? 'opacity-0 z-0' : 'opacity-100 z-50'} flex justify-start items-center flex-col pt-[100px]`}>
                            <div className="drop-shadow-xl mb-10 relative flex justify-evenly items-center bg-[#3a3939] w-[80%] md:w-[60%] lg:w-[60%] flex-col rounded-xl pt-5 pb-5">
                                <input defaultValue="" placeholder="Title" className="bg-[#3a3939] text-xl md:text-3xl lg:text-3xl text-secondary border-b-2 border-white outline-none w-[70%] md:w-[50%] lg:w-[50%] text-center" type="email" onChange={(event) => {setCurrentCampaign({...currentCampaign, title: event?.target.value});}}/>
                            </div>
                            <div className="drop-shadow-xl mb-10 relative flex justify-evenly items-center bg-[#3a3939] w-[80%] md:w-[60%] lg:w-[60%] flex-col rounded-xl pt-5 pb-5">
                                <textarea  defaultValue="" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' placeholder="Description" cols={20} rows={3} className="p-2 bg-background rounded-md text-lg md:text-xl lg:text-xl text-secondary border-md border border-white outline-none w-[70%] md:w-[50%] lg:w-[50%] text-center" type="textarea" onChange={(event) => {setCurrentCampaign({...currentCampaign, description: event?.target.value});}}/>
                            </div>
                            <div className="w-full flex flex-row items-center justify-center">
                                <button onClick={() => {setTitle(false)}} className="text-xl text-white w-[150px] pt-2 pb-2 pl-5 mt-5 mr-2 mb-5  pr-5 font-bold bg-[#A1A1A1]  border rounded-md border-[#A1A1A1] " >Cancel</button>
                                <button disabled={currentCampaign.title !== "" && currentCampaign.description !== "" ? false : true} onClick={() => {startCampaign()}} className={`${currentCampaign.title !== "" && currentCampaign.description !== "" ? 'cursor-pointer opacity-100' : 'cursor-default opacity-50'} transition-200 text-xl text-background w-[150px] pt-2 pb-2 pl-5 mt-5 ml-2 mb-5 pr-5 font-bold bg-primary border rounded-md border-primary`} >Continue</button>
                            </div>
                        </div>
                    )}

                    <div className="w-full h-[10%] flex justify-between flex-row mt-5 z-10">
                        <div className="text-xl md:text-3xl lg:text-3xl text-white mt-3 font-bold ml-5">Campaigns</div>
                        <button className="z-10 text-lg md:text-xl lg:text-lg text-background pt-0 pb-0 pl-5 md:pl-10 lg-pl-10 mt-4 md:mt-5 lg-mt-5 mr-5 mb-5 ml-5 pr-5 md:pr-10 lg:pr-10 font-bold bg-primary border rounded-md border-primary" onClick={() => {newCampaign()}}>New</button>
                    </div>
                    <div className="w-full h-[90%] flex justify-center items-center">
                        {localStorage.campaigns == null ? (
                            <Spinner color="white"/>
                        ) : localStorage.campaigns?.length == 0 ? (
                            <div className="text-white text-2xl">No campaigns yet!</div>
                        ) : localStorage.campaigns?.error == "true" ? (
                            <div className="">{localStorage.campaigns.message}</div>
                        ) : (
                            <div className="w-[95%] flex items-center flex-col h-full pt-10">
                                {localStorage.campaigns.map((x, i) => {
                                    return (
                                        <button onClick={() => {viewCampaign(x)}} className="mb-5 w-full bg-lighterBackground h-[100px] md:h-[170px] lg:h-[170px] border rounded-2xl md:rounded-3xl lg:rounded-3xl flex justify-between items-center shadow-lg border-lighterBackground hover:border-white hover:cursor-pointer transition-200 relative">
                                            {x.status !== "active" && (
                                                <button onClick={(e) => {setSecurityInfoShowing(3); setDeletingCampaign(x); e.stopPropagation();}} className="bg-background absolute -top-1 -right-1 rounded-[50%] w-5 h-5 drop-shadow-xl flex justify-center items-center text-white text-sm border border-black hover:border-white">x</button>
                                            )}
                                            <div className="h-full flex flex-row items-center">
                                                <div className="overflow-hidden rounded-tl-2xl rounded-bl-2xl h-full w-[100px] md:w-[170px] lg:w-[170px] bg-black overflow-hidden flex justify-center items-center">
                                                    <img src={x.pics?.length > 0 ? (x.pics[0]?.name !== undefined ? URL.createObjectURL(x.pics[0]) : x.pics[0]) : "https://png.pngtree.com/png-vector/20240104/ourmid/pngtree-white-question-mark-3d-sign-png-image_11107286.png"} alt="no image yet" className="" />
                                                </div>
                                                <div className="text-secondary font-bold text-md md:text-xl lg-text-xl pl-[10px] md:pl-[75px] lg:pl-[75px]">{x.title}</div>
                                            </div>
                                            <div className={`${x.status == "active" ? "text-green-500" : x.status == "inactive" ? "text-red-500" : "text-yellow-500"} pr-[10px] md:pr-[75px] lg:pr-[75px] font-bold text-sm md:text-lg lg:text-lg`}>{x.status}</div>
                                        </button>
                                    )
                                })}
                            </div>
                        )}
                </div>
            </div>
            ) : createCampaign < 4 ? (
                <div className="h-full w-[100%] bg-background overflow-hidden relative">
                        <div className={`overflow-y-scroll w-[300%] h-full flex flex-row relative ${createCampaign == 1 ? "right-0" : createCampaign == 2 ? "right-[100%]" : createCampaign == 3 ? "right-[200%]" : "right-[300%]"}`}>
                            <div className="h-full w-1/3 bg-background">
                                <div className="w-full h-[10%] flex justify-between flex-row">
                                    <button onClick={() => {setCreateCampaign(0); setCurrentCampaign({ title: "", description: "" })}} className="h-full text-xl text-white p-5 font-bold flex flex-row items-center justify-center"><FaArrowLeftLong color={'white'} size={25} className="mr-3"/>Campaigns</button>
                                    <button disabled={currentCampaign.package !== "" ? false : true} onClick={() => {nextPage(2)}} className={`text-xl ${currentCampaign.package !== "" ? "text-white" : "text-[#A1A1A1]"} p-5 flex flex-row items-center justify-center font-bold`}><div>Details</div><FaArrowRightLong color={currentCampaign.package !== "" ? "white" : "#A1A1A1"} size={25} className="ml-3"/></button>
                                </div>
                                <div className="h-[90%] w-full flex justify-evenly items-center flex-col md:flex-row lg:flex-row">
                                    <button onClick={() => {currentCampaign.package == "basic" ? setCurrentCampaign({...currentCampaign, package: ""}) : setPackage("basic") }} className={`lg:w-[25%] md:w-[30%] w-[80%] mb-10 h-[30%] md:h-3/4 lg:h-3/4 flex flex-row md:flex-col lg:flex-col bg-background border ${currentCampaign.package == 'basic' ? "border-[#BC2929]" : "border-[#3a3939]"} drop-shadow-md hover:drop-shadow-2xl rounded-2xl flex`}>
                                        <div className="w-[40%] md:w-full lg:w-full h-full md:h-1/2 lg:h-1/2 border rounded-2xl bg-[#3a3939] border-[#3a3939] flex items-center flex-col justify-evenly">
                                            <div className="text-2xl md:text-3xl lg:text-3xl font-bold text-[#BC2929]">Basic</div>
                                            <div>
                                                <div className="text-[40px] md:text-[50px] lg:text-[50px] font-light text-white">$2.99</div>
                                                <div className="text-[10px] font-light text-[#A1A1A1]">per active day</div>
                                            </div>
                                        </div>
                                        <div className="w-[60%] md:w-full lg:w-full h-full md:h-1/2 lg:h-1/2 flex justify-center items-center text-[#A1A1A1] flex-col">
                                            <ul style={{ listStyle: 'circle' }} className="flex text-left items-start flex-col justify-evenly w-[80%] ml-5">
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold ">10-mile advertising radius</li>
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold">Single photo post</li>
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold">3-second long swipe stall</li>
                                            </ul>
                                        </div>
                                    </button>
                                    <button onClick={() => {currentCampaign.package == "standard" ? setCurrentCampaign({...currentCampaign, package: ""}) : setPackage("standard")}} className={`lg:w-[25%] md:w-[30%] w-[85%] h-[30%] md:h-[81%] lg:h-[81%] mb-10 flex flex-row md:flex-col lg:flex-col bg-background border ${currentCampaign.package == "standard" ? "border-[#2259C2]" : "border-[#3a3939]"} rounded-2xl flex drop-shadow-md hover:drop-shadow-2xl`}>
                                        <div className="w-[40%] md:w-full lg:w-full h-full md:h-1/2 lg:h-1/2 border rounded-2xl bg-[#3a3939] border-[#3a3939] flex items-center flex-col justify-evenly">
                                            <div className="text-2xl md:text-3xl lg:text-3xl font-bold text-[#2259C2]">Standard</div>
                                            <div>
                                                <div className="text-[40px] md:text-[50px] lg:text-[50px] font-light text-white">$5.99</div>
                                                <div className="text-[10px] font-light text-[#A1A1A1]">per active day</div>
                                            </div>
                                        </div>
                                        <div className="w-[60%] md:w-full lg:w-full h-full md:h-1/2 lg:h-1/2 flex justify-center items-center text-[#A1A1A1] flex-col">
                                            <ul style={{ listStyle: 'circle' }} className="flex text-left items-start flex-col justify-evenly w-[80%] ml-5">
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold ">15-mile advertising radius</li>
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold ">Up to 3 photos in post</li>
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold ">3-second long swipe stall</li>
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold ">QR code scanable promotion</li>
                                            </ul>
                                        </div>

                                    </button>
                                    <button onClick={() => {currentCampaign.package == "premium" ? setCurrentCampaign({...currentCampaign, package: ""}) : setCurrentCampaign({...currentCampaign, package: "premium"})}} className={`mb-10 lg:w-[25%] md:w-[30%] w-[80%] h-[30%] md:h-3/4 lg:h-3/4 flex flex-row md:flex-col lg:flex-col bg-background border ${currentCampaign.package == "premium" ? "border-[#BF7722]" : "border-[#3a3939]"} rounded-2xl flex drop-shadow-md hover:drop-shadow-2xl`}>
                                        <div className="w-[40%] md:w-full lg:w-full h-full md:h-1/2 lg:h-1/2 border rounded-2xl bg-[#3a3939] border-[#3a3939] flex items-center flex-col justify-evenly">
                                            <div className="text-2xl md:text-3xl lg:text-3xl font-bold text-[#BF7722]">Premium</div>
                                            <div>
                                                <div className="text-[40px] md:text-[50px] lg:text-[50px] font-light text-white">$9.99</div>
                                                <div className="text-[10px] font-light text-[#A1A1A1]">per active day</div>
                                            </div>
                                        </div>
                                        <div className="w-[60%] md:w-full lg:w-full h-full md:h-1/2 lg:h-1/2 flex justify-center items-center text-[#A1A1A1] flex-col">
                                            <ul style={{ listStyle: 'circle' }} className="flex text-left items-start flex-col justify-evenly w-[80%] ml-5">
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold ">20-mile advertising radius</li>
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold ">Unlimited photos in post</li>
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold ">5-second long swipe stall</li>
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold ">QR code scanable promotion</li>
                                                <li className="mb-[3px] md:mb-[10px] lg:mb-[10px] text-xs md:text-md lg:text-lg font-bold ">External link in post</li>
                                            </ul>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className="h-full w-1/3 bg-background">
                                <div className="w-full h-[10%] flex justify-between flex-row">
                                    <button onClick={() => {setCreateCampaign(1)}} className="h-full text-xl text-white p-5 font-bold flex flex-row items-center justify-center"><FaArrowLeftLong color={'white'} size={25} className="mr-3"/>Packages</button>
                                    <button disabled={currentCampaign.pics.length === 0 || hasChangedLocation <= 1 ? true : false}onClick={() => {setCreateCampaign(3); console.log(currentCampaign)}} className={`text-xl ${currentCampaign.pics.length > 0 && hasChangedLocation > 1 ? "text-white" : "text-[#A1A1A1]"} p-5 flex flex-row items-center justify-center font-bold`}><div>Payment</div><FaArrowRightLong color={currentCampaign.pics.length > 0 && hasChangedLocation > 1 ? "white" : "#A1A1A1"} size={25} className="ml-3"/></button>
                                </div>
                                <div className="h-[90%] w-full flex flex-col items-center overflow-y-scroll no-scrollbar ">
                                    <div className="text-lg text-white mb-3 font-bold">Add Pictures</div>
                                    <input ref={inputRef} type="file" name="newImage" className="h-0 w-0" onChange={(e) => {console.log(currentCampaign); setCurrentCampaign({...currentCampaign, pics: [...currentCampaign.pics, e?.target?.files[0]]})}} />
                                    <div className={`flex-shrink-0 w-4/5 md:w-3/5 lg:w-3/5 border-[#3a3939] border bg-[#3a3939] rounded-xl pt-[10px] pb-[10px] flex ${currentCampaign.pics?.length < 2 ? "justify-center md:justify-center lg:justify-center" : currentCampaign.pics?.length < 3 ? "justify-start md:justify-center lg:justify-center" : currentCampaign.pics?.length < 3 ? "justify-start md:justify-start lg:justify-center" : "justify-start" }items-center flex-row mb-10 overflow-y-hidden overflow-x-auto`}>
                                        {currentCampaign.pics?.map((x,i) => {
                                            return (
                                                <div className="mr-5 h-[286px] w-[202px] flex-shrink-0 relative">
                                                    <button onClick={(e) => {setCurrentCampaign({...currentCampaign, pics: currentCampaign.pics.filter((pic) => {return pic !== x})})}} className="absolute -top-1 -right-2 text-md text-white text-sm bg-background border border-white rounded-[50%] w-5 h-5 flex justify-center items-center" >x</button>
                                                    <div className=" overflow-hidden flex justify-center items-center border hover:border-white border-[#949494] bg-background rounded-2xl text-[50px] font-bold text-white h-full w-full">
                                                        <img src={x?.name !== undefined ? URL.createObjectURL(x) : x} className="" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <button disabled={(currentCampaign.package === "basic" && currentCampaign.pics.length >= 1) || (currentCampaign.package === "standard" && currentCampaign.pics.length >= 3) ? true : false} 
                                            onClick={() => {inputRef.current.click()}} 
                                            className={`flex-shrink-0 flex justify-center items-center border ${(currentCampaign.package === "basic" && currentCampaign.pics.length >= 1) || (currentCampaign.package === "standard" && currentCampaign.pics.length >= 3) ? "cursor-not-allowed" : "hover:border-white cursor-pointer"} border-[#949494] bg-background rounded-2xl text-[50px] font-bold text-white h-[286px] w-[202px]`} >
                                                {(currentCampaign.package === "basic" && currentCampaign.pics.length >= 1) || (currentCampaign.package === "standard" && currentCampaign.pics.length >= 3) ? (
                                                    <FaLock name="lock" size={30} color="white"/>
                                                ) : (
                                                    <div>+</div>
                                                )}
                                        </button>
                                    </div>
                                    <div className="text-lg text-white mb-3 font-bold">Set Up Custom QR-Scanable Deal</div>
                                    <div className={`relative drop-shadow-xl w-4/5 md:w-3/5 lg:w-3/5 border-[#3a3939] border bg-[#3a3939] ${currentCampaign.package === "basic" ? "opacity-75" : "opacity-100" } rounded-xl pt-20 pb-5 flex justify-between items-center flex-col mb-10`}>
                                        {currentCampaign.package === "basic" && (
                                            <FaLock name="lock" size={30} color="white" className="absolute top-3 left-3"/>
                                        )}
                                        <input disabled={currentCampaign.package === "basic" ? true : false} ref={dealPromotionRef} className="mb-10 p-5 md:w-1/2 lg:w-1/2 bg-[#3a3939] text-md text-secondary border-b-2 border-white outline-none w-5/6 h-[10%] text-center" placeholder="EX: 20% off final purchase!" onChange={(e) => {setCurrentCampaign({...currentCampaign, deal: {promotion: e?.target?.value.trim() === "" ? null : e?.target?.value, code: currentCampaign?.deal?.code}})}}/>
                                        <input disabled={currentCampaign.package === "basic" ? true : false} ref={dealCodeRef} className="text-white text-center h-[50px] border border-[#949494] rounded-xl w-2/3 md:w-1/3 lg:w-1/3 bg-background p-5" placeholder="Security Code" onChange={(e) => {setCurrentCampaign({...currentCampaign, deal: {promotion: currentCampaign?.deal?.promotion, code: e?.target?.value.trim() === "" ? null : e?.target?.value}})}}/>
                                        <button onClick={() => {setSecurityInfoShowing(1)}} className="w-4 h-4 border-2 border-[#949494] bg-[#3a3939] flex justify-center items-center rounded-[50%] text-[#949494] font-bold text-[9px]">i</button>
                                    </div>
                                    <div className="text-lg text-white mb-3 font-bold">External Website</div>
                                    <div className={`relative drop-shadow-xl w-4/5 md:w-3/5 lg:w-3/5 border-[#3a3939] border bg-[#3a3939] ${currentCampaign.package !== "premium" ? "opacity-75" : "opacity-100" } rounded-xl pt-10 pb-10 flex justify-between items-center flex-col mb-10`}>
                                        {currentCampaign.package !== "premium" && (
                                            <FaLock name="lock" size={30} color="white" className="absolute top-3 left-3"/>
                                        )}
                                        <input disabled={currentCampaign.package !== "premium" ? true : false} ref={externalWebsiteRef} className={`p-5 w-5/6 md:w-1/2 lg:w-1/2 bg-transparent text-md text-secondary border-b-2 border-white outline-none h-[10%] text-center mb-5`} placeholder="EX: https://olsonify.com/swapt/business" onChange={(e) => {setCurrentCampaign({...currentCampaign, external_website: {website: e?.target?.value.trim() === "" ? null : e?.target?.value, name: currentCampaign?.external_website?.name}})}}/>
                                        <input disabled={currentCampaign.package !== "premium" ? true : false} ref={externalWebsiteNameRef} className={`p-5 w-5/6 md:w-1/2 lg:w-1/2 bg-transparent text-md text-secondary border-b-2 border-white outline-none h-[10%] text-center`} placeholder="EX: Visit our Website today!" onChange={(e) => {setCurrentCampaign({...currentCampaign, external_website: {website: currentCampaign?.external_website?.website, name: e?.target?.value.trim() === "" ? null : e?.target?.value}})}}/>
                                    </div>
                                    <div className="text-lg text-white mb-3 font-bold">Location Selector</div>
                                    <div className="drop-shadow-xl w-4/5 md:w-3/5 lg:w-3/5 border-[#3a3939] border bg-[#3a3939] rounded-xl pt-20 flex justify-between items-center flex-col mb-10">
                                        <div className="w-[80%] md:w-[40%] lg:w-[40%] text-lg text-white text-center mb-10">Please select the radius that you want to advertise your campaign in.</div>
                                        <div className="h-[256px] md:h-[400px] lg:h-[400px] w-[80%] rounded-xl overflow-hidden drop-shadow-lg">
                                        <APIProvider apiKey={"AIzaSyD5JB_56C1k27fQc8SoGINHi2_fbNxyH2s"}>
                                            <Map
                                            style={{width: '100%', height: '100%'}}
                                            center={location}
                                            defaultZoom={11}
                                            gestureHandling={'greedy'}
                                            disableDefaultUI={false}
                                            >
                                                <Marker ref={markerRef} draggable={true} position={center} onDragEnd={(e) => {setCurrentCampaign({...currentCampaign, location: {type: 'Point', coordinates: [e?.latLng?.lng() ?? 0, e?.latLng?.lat() ?? 0]}}); setLocation({lat: e.latLng?.lat() ?? 0, lng: e.latLng?.lng() ?? 0})}} onDrag={e => setCenter({lat: e.latLng?.lat() ?? 0, lng: e.latLng?.lng() ?? 0})}/>
                                                <Circle draggable={true} center={center} radius={currentCampaign.package === "basic" ? 16092 : currentCampaign.package === "standard" ? 24138 : 32184} onCenterChanged={changeCenter} onDragEnd={(e) => {console.log(markerRef.current); setCurrentCampaign({...currentCampaign, location: {type: 'Point', coordinates: [markerRef.current.position.lng()  ?? 0, markerRef.current.position.lat() ?? 0]}}); setLocation({lat: markerRef.current.position.lat() ?? 0, lng: markerRef.current.position.lng()  ?? 0})}} />
                                            </Map>
                                        </APIProvider>
                                        </div>
                                        <button onClick={() => {getCurrentLocation()}} className="p-3 bg-primary text-background font-bold text-lg mt-10 mb-10 rounded-lg">
                                            {locationLoading ? (
                                                <Spinner color="#222222"/>
                                            ) : (
                                                <div>Jump to current Location</div>
                                            )}
                                            
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="h-full w-1/3 bg-background">
                                <div className="w-full h-[10%] flex justify-between flex-row">
                                    <button onClick={() => {setCreateCampaign(2)}} className="h-full text-xl text-white p-5 font-bold flex flex-row items-center justify-center"><FaArrowLeftLong color={'white'} size={25} className="mr-3"/>Details</button>
                                </div>
                                <div className="w-full flex flex-col items-center text-lg text-white mb-3 font-bold">Pay Ahead and Save</div>
                                <div className="mx-auto drop-shadow-xl w-[85%] md:w-3/4 lg:w-3/5 border-[#3a3939] border bg-[#3a3939] rounded-xl flex justify-evenly items-center flex-row mb-10 pt-3 pb-3">
                                    <div className={`text-white h-[150px] w-1/4 bg-[#3a3939]'} flex justify-evenly items-center flex-col`}>
                                        <div className="text-xl font-bold text-center">Pay per day</div>
                                        <div>{currentCampaign.package === 'basic' ? '$2.99/day' : currentCampaign.package === 'standard' ? '$5.99/day' : '$9.99/day'}</div>
                                        <input type="checkbox" className="" checked={payAhead === 0 ? true : false} onChange={() => {setPayAhead(0)}}/>
                                    </div>
                                    <div className={`text-white h-[150px] w-1/4 bg-[#3a3939] flex justify-evenly items-center flex-col`}>
                                        <div className="text-xl font-bold text-center">Week ahead</div>
                                        <div className="flex flex-row items-center justify-center">
                                            <div className="line-through">${currentCampaign.package === 'basic' ? ((7 * 2.99)).toFixed(2) : currentCampaign.package === 'standard' ? ((7 * 5.99)).toFixed(2)  : ((7 * 9.99)).toFixed(2)}</div>
                                            <div className="text-green-500 ml-1 md:ml-3 lg:ml-3">-5%</div>
                                        </div>
                                        <input type="checkbox" className="" checked={payAhead === 1 ? true : false} onChange={() => {setPayAhead(1)}}/>
                                    </div>
                                    <div className={`text-white h-[150px] w-1/4 bg-[#3a3939] flex justify-evenly items-center flex-col`}>
                                        <div className="text-xl font-bold text-center">Month ahead</div>
                                        <div className="flex flex-row items-center justify-center">
                                            <div className="line-through">${currentCampaign.package === 'basic' ? ((30 * 2.99)).toFixed(2) : currentCampaign.package === 'standard' ? ((30 * 5.99)).toFixed(2)  : ((30 * 9.99)).toFixed(2)}</div>
                                            <div className="text-green-500 ml-1 md:ml-3 lg:ml-3">-10%</div>
                                        </div>
                                        <input type="checkbox" className="" checked={payAhead === 2 ? true : false} onChange={() => {setPayAhead(2)}}/>
                                    </div>
                                </div>
                                <div className="w-full flex flex-col items-center text-lg text-white mb-3 font-bold">Payment Method</div>
                                <div className="mx-auto drop-shadow-xl w-[85%] md:w-3/5 lg:w-3/5 border-[#3a3939] border bg-[#3a3939] rounded-xl flex justify-evenly items-center flex-col ">
                                    {localStorage.profile.cards.length > 0 && (
                                        <div className={`rounded-xl pt-2 pb-2 relative w-full h-[20%] drop-shadow-md bg-[#3a3939] flex justify-center items-center text-lg text-white  ${localStorage.profile.cards.length > 0 && !defaultPayment ? 'opacity-60 transition-300' : 'opacity-100'}`}>
                                            <input type="checkbox" className="absolute left-1 md:left-10 lg:left-10" checked={defaultPayment ? true : false} onChange={() => {setDefaultPayment(true)}}/>
                                            Use your default card on file.
                                        </div>
                                    )}
                                    <div className={`pt-2 relative w-full ${localStorage.profile.cards.length > 0 ? 'h-[90%]' : 'h-full'} ${localStorage.profile.cards.length > 0 && defaultPayment ? 'opacity-60 transition-300' : 'opacity-100'} flex flex-col items-center `}>
                                    {localStorage.profile.cards.length > 0 && (
                                        <input type="checkbox" className="absolute left-1 md:left-10 lg:left-10 mt-2" checked={!defaultPayment ? true : false} onChange={() => {setDefaultPayment(false)}}/>
                                    )}
                                    <div className="text-lg mt-2 mb-3 text-white">Add a new payment method.</div>
                                        <Form onSubmit={() => {alert('submitted')}} render={() => { 
                                            return (
                                            <form>
                                                <div className="flex flex-row items-center justify-between w-full">
                                                <Field
                                                    disabled={localStorage.profile.cards.length > 0 && defaultPayment}
                                                    className="w-3/4 rounded-md drop-shadow-md p-3 mb-2 mt-2 mr-2"
                                                    name="number"
                                                    component="input"
                                                    type="text"
                                                    pattern="[\d| ]{16,22}"
                                                    placeholder="Card Number"
                                                    format={formatCreditCardNumber}
                                                    ref={cardNumberRef}
                                                />
                                                <Field
                                                    disabled={localStorage.profile.cards.length > 0 && defaultPayment}
                                                    className="w-1/4 rounded-md drop-shadow-md p-3"
                                                    name="cvc"
                                                    component="input"
                                                    type="text"
                                                    pattern="\d{3,4}"
                                                    placeholder="CVC"
                                                    format={formatCVC}
                                                    ref={cvcRef}
                                                />
                                                </div>
                                                <div className="flex flex-row items-center justify-between w-full mb-2">
                                                <Field
                                                    disabled={localStorage.profile.cards.length > 0 && defaultPayment}
                                                    className="w-1/4 rounded-md drop-shadow-md p-3 mr-2"
                                                    name="expiry"
                                                    component="input"
                                                    type="text"
                                                    pattern="\d\d/\d\d"
                                                    placeholder="Exp."
                                                    format={formatExpirationDate}
                                                    ref={expirationRef}
                                                />

                                                <Field
                                                    disabled={localStorage.profile.cards.length > 0 && defaultPayment}
                                                    className="w-3/4 rounded-md drop-shadow-md p-3"
                                                    name="name"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Name on Card"
                                                    ref={cardNameRef}
                                                />
                                                </div>
                                            </form>
                                            )}}>
                                        </Form>
                                    </div>
                                </div>
                                <div className="text-xl font-bold text-white text-center mt-5 md:mt-10 lg:mt-10">
                                    Due Today: 
                                    <div className="text-3xl">
                                    {payAhead == 1 ? (
                                        <div>${currentCampaign.package === 'basic' ? ((7 * 2.99) * 0.95).toFixed(2) : currentCampaign.package === 'standard' ? ((7 * 5.99) * 0.95).toFixed(2)  : ((7 * 9.99) * 0.95).toFixed(2) }</div>
                                    ) : payAhead === 2 ? (
                                        <div>${currentCampaign.package === 'basic' ? ((30 * 2.99) * 0.9).toFixed(2)  : currentCampaign.package === 'standard' ? ((30 * 5.99) * 0.9).toFixed(2)  : ((30 * 9.99) * 0.9).toFixed(2) }</div>
                                    ) : (
                                        <div>$0.00</div>
                                    )}
                                    </div>
                                </div>
                                <div className="w-full flex flex-col items-center">
                                    <button onClick={() => {publishCampaign()}} className="mx-auto mb-20 p-3 bg-primary text-lg text-background font-bold rounded-md mt-10">
                                        {publishingCampaign ? (
                                            <Spinner size="small" />
                                        ) : (
                                            <div>{currentCampaign.status === 'paused' ? "Resume Campaign" : "Start Campaign"}</div>
                                        )}
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                </div>
            ) : (
                <div className="w-full h-full overflow-x-hidden" >
                    <div className="w-full h-[5%] relative flex items-center justify-center mt-10 md:mt-10 lg:mt-0">
                        <button className="absolute left-5" onClick={() => {setCreateCampaign(0)}}><FaArrowLeftLong color={"white"} size={25} className="ml-3"/></button>
                        <div className="text-white text-2xl">{currentCampaign.title}</div>
                    </div>
                    <Statistics className="mt-10" campaigns={[currentCampaign]} interactions={campaignInteractions} />
                    <div className="ml-[2.5%] flex justify-start items-center w-full">
                        <button onClick={() => {setSecurityInfoShowing(2)}} className="mb-5 bg-yellow-700 p-3 text-md text-background mt-5 rounded-md">Pause Campaign</button>
                    </div>
                </div>
            )}
            
        </div>
    )
}
