import React from 'react';

import About from '../components/About.tsx';
import Canvas from '../components/Canvas.tsx';
import Features from '../components/Features.tsx';
import Header from '../components/Header.tsx';
import LazyShow from '../components/LazyShow.tsx';
import MainHero from '../components/MainHero.tsx';
import MainHeroImage from '../components/MainHeroImage.tsx';
import Pricing from '../components/Pricing.tsx';
import Product from '../components/Product.tsx';

export const SwaptBusinessLandingPage = () => {
  return (
    <div className={`bg-background grid gap-y-16 overflow-hidden`}>
      <div className={`relative bg-background`}>
        <div className="max-w-7xl mx-auto">
          <div
            className={`relative z-10 pb-8 bg-background sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}
          >
            <Header />
            <MainHero />
          </div>
        </div>
        <MainHeroImage />
      </div>
      <Canvas />
      <LazyShow>
        <>
          <Product />
          <Canvas />
        </>
      </LazyShow>
      <LazyShow>
        <>
          <Features />
          <Canvas />
        </>
      </LazyShow>
      <LazyShow>
        <Pricing />
      </LazyShow>
      <LazyShow>
        <>
          <Canvas />
          <About />
        </>
      </LazyShow>
    </div>
  );
};

