import React from "react";
import { useEffect, useState, useRef } from 'react';
import { mongoQuery } from "../backend/database";
import { useSearchParams } from "react-router-dom";
import "../styles/styles.css";
import { getCookie } from './Home';
import { useNavigate } from 'react-router-dom';

function MongoQueries(){

    const mongoInput = useRef();
    const db = useRef();
    const collection = useRef();
    const operation = useRef();
    const navigate = useNavigate();

    const [output, setOutput] = useState("this is a test");


    useEffect(() => {
        const cookieValue = getCookie("id");
        if(cookieValue !== '65cad2b1c529d7ee054af60e') {
            navigate('/swapt/business');
        }
    },[]);

    const submitQuery = () => {
        mongoQuery(mongoInput.current.value, db.current.value, collection.current.value, operation.current.value).then((response) => {
            setOutput(response.message);
        });
    }

  return (
    <div className="w-full h-full overflow-hidden">
        <input type="text" className="border border-black" placeholder="db" ref={db} />
        <input type="text" className="border border-black" placeholder="collection" ref={collection} />
        <input type="text" className="border border-black" placeholder="operation" ref={operation} />
        <input type="text" className="border border-black" placeholder="query" ref={mongoInput} />
        <button className="bg-turquoise border rounded-md" onClick={() => {submitQuery()}} >Submit Query</button>
        <div><pre>{JSON.stringify(output, null, 2) }</pre></div>
    </div>
  )
}

export default MongoQueries;

