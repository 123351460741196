import React from "react";
import { useState, useEffect, useRef } from 'react';
import "../styles/styles.css";
import { Spinner } from "react-activity";
import { getCampaigns, initializeCampaign, createCampaignPayment, publishNewCampaign, getCampaignInteractions, pauseCampaign, deleteCampaign, resumeCampaign, changeDefaultPayment, addCard, removePaymentMethod, createCustomer } from "../backend/database";
import { getCookie } from "../Pages/Home";
import { setCampaigns, setProfile } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyExchangeTwoTone } from "@mui/icons-material";
import {APIProvider, AdvancedMarker, Map, Marker} from '@vis.gl/react-google-maps';
import { Circle } from './circle.tsx';
import { FaLock } from "react-icons/fa";
import { FaArrowRightLong, FaArrowLeftLong } from 'react-icons/fa6';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import { Form, Field } from "react-final-form";
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
  } from "./cardUtils";
import Styles from './Styles.js';
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../backend/firebaseConfig";
import { Statistics } from './Statistics';
import { IoIosClose } from "react-icons/io";

export const Billing = () => {

    const [allBills, setBills] = useState([]);
    const [billsTotal, setTotal] = useState(0.00);
    const [defaultPayment, setDefaultPayment] = useState(0);
    const [confirmationShowing, setConfirmationShowing] = useState(0);
    const [newCardLoading, setNewCardLoading] = useState(false);
    const [removeCard, setRemoveCard] = useState(0);
    const localStorage = useSelector(state => state.localStorage);

    const cardNumberRef = useRef();
    const cvcRef = useRef();
    const expirationRef = useRef();
    const cardNameRef = useRef();


    useEffect(() => {
        //setup stripe utilities
        var s = window.document.createElement("script");
        s.id = "stripe-script";
        s.type = "text/javascript";
        s.src = "https://js.stripe.com/v2/";
        s.onload = () => {
            window["Stripe"].setPublishableKey(
            "pk_test_51OygOqEY0IOq0W591k71UJxTdIvZXfhQrIekZZQegtNG4UEAwvC0dFlYLZV1z3e8zznVlR2TVuxPn8UwviZuxIgu00IhDVQwcp"
            );
        };
        window.document.body.appendChild(s);

        //calculate bill
        let bills = [];
        let total = 0;
        let currentDate = new Date();
        currentDate.setHours(0,0,0,0);
        localStorage.campaigns.map((x,i) => {
            if (x.status === 'active') {
                let lastPaid = new Date(x.paidUntil);
                lastPaid.setHours(0,0,0,0);

                let days = (currentDate.getTime() - lastPaid.getTime()) / (24 * 60 * 60 * 1000);
                let payRate = x.package === 'basic' ? 2.99 : x.package === 'standard' ? 5.99 : 9.99;

                if (x.payAhead === "week") {
                    const raw_cost = Math.ceil(days / 7) * payRate * 7;
                    const discount = raw_cost * 0.05;
                    bills.push({cost: raw_cost, discount: discount, discountRate: "5%", discountType: "week", days: days, payRate: payRate, index: i});
                    total += (raw_cost - discount);
                }else if(x.payAhead === "month") {
                    const raw_cost = Math.ceil(days / 30) * payRate * 30;
                    const discount = raw_cost * 0.1;
                    bills.push({cost: raw_cost, discount: discount, discountRate: "10%", discountType: "month", days: days, payRate: payRate, index: i});
                    total += (raw_cost - discount);
                }else {
                    const calculation = (days * payRate);
                    bills.push({cost: calculation, days: days, payRate: payRate, index: i});
                    total += calculation;
                }

            }
        });
        setBills(bills);
        setTotal(total);
    },[]);

    const dispatch = useDispatch();

   const addCardToAccount = () => {
        setNewCardLoading(true);
        window.Stripe.card.createToken(
            {
            number: cardNumberRef.current.value,
            exp_month: expirationRef.current.value.split("/")[0],
            exp_year: expirationRef.current.value.split("/")[1],
            cvc: cvcRef.current.value,
            name: cardNameRef.current.value,
            },
            (status, response) => {
            if (status === 200) {
                if (localStorage.profile.customerid === undefined) {
                    createCustomer(localStorage.profile, response).then((res) => {
                        if (res.message === "success") {
                            dispatch(setProfile({...localStorage.profile, customerid: res.customerid, cards: [response]}));
                            setNewCardLoading(false);
                            cardNumberRef.current.value = "";
                            cardNameRef.current.value = "";
                            cvcRef.current.value = "";
                            expirationRef.current.value = "";
                        }else {
                            alert(res.message);
                            setNewCardLoading(false);
                        }
                    })
                }else {
                    addCard(response, localStorage.profile).then((res) => {
                        if (res.message === "success") {
                            dispatch(setProfile({...localStorage.profile, cards: [...localStorage.profile.cards, response]}));
                            setNewCardLoading(false);
                            cardNumberRef.current.value = "";
                            cardNameRef.current.value = "";
                            cvcRef.current.value = "";
                            expirationRef.current.value = "";
                        }else {
                            alert(res.message);
                            setNewCardLoading(false);
                        }
                    })
                }
            } else {
                console.log(response.error.message);
                setNewCardLoading(false);
            }
            }
        );
   }

   const changeDefaultPaymentPressed = () => {
        changeDefaultPayment(defaultPayment, localStorage.profile).then((response) => {
            if (response.message === "success") {
                let newCards = [];
                localStorage.profile.cards.map((x,i) => {
                    if (i !== defaultPayment) {
                        newCards.push(x);
                    }
                });
                newCards.unshift(localStorage.profile.cards[defaultPayment]);
                dispatch(setProfile({...localStorage.profile, cards: newCards}));
                setDefaultPayment(0);
            }else{
                alert(response.message);
            }
        })
   }

   const removePaymentMethodPressed = () => {
    removePaymentMethod(removeCard, localStorage.profile).then((response) => {
        if (response.message == "success") {
            let newCards = [];
            localStorage.profile.cards.map((x,i) => {
                if (i !== removeCard) {
                    newCards.push(x);
                }
            });
            dispatch(setProfile({...localStorage.profile, cards: newCards}));
        }else {
            alert(response.message);
        }
    });
   }


    return (
        <div className=" w-full h-full overflow-y-scroll overflow-x-hidden">
            {confirmationShowing > 0 && (
                <div className="w-full md:w-full lg:w-5/6 h-full flex items-center justify-center absolute top-0 z-20">
                    <div className="border-2 border-background p-6 bg-[#3a3939] z-10 absolute bottom-10 w-[500px] h-[500px] rounded-xl drop-shadow-2xl flex justify-center items-center flex-col relative">
                            <button onClick={() => {setConfirmationShowing(false); setDefaultPayment(0);}} className="absolute top-2 right-2 text-md w-10 h-10 text-white"><IoIosClose size="small"/></button>
                            <div className="text-lg text-white text-center w-3/5">{confirmationShowing == 1 ? "Set this card as your new default payment method" : "Are you sure you want to remove this payment method from your account?"}</div>
                            {confirmationShowing == 1 ? (
                                <button onClick={() => {setConfirmationShowing(false); changeDefaultPaymentPressed()}} className="mb-5 bg-yellow-700 p-3 text-md text-background mt-10 rounded-md">Confirm</button>
                            ) : (
                                <button onClick={() => {setConfirmationShowing(false); removePaymentMethodPressed()}} className="mb-5 bg-yellow-700 p-3 text-md text-background mt-10 rounded-md">Remove</button>
                            )}
                            
                    </div> 
                </div>
            )}
            <div className="mt-6 w-full flex items-center flex-col text-white text-lg mb-2 font-bold">Cost and Breakdown</div>
            <div className="relative h-[300px] mx-auto w-[95%] md:w-[75%] lg:w-[75%] bg-lighterBackground rounded-xl drop-shadow-md p-8 flex flex-col md:flex-row lg:flex-row items-center justify-start">
                <div className="relative w-full md:w-1/3 lg:w-1/3 h-1/3 md:h-full lg:h-full flex justify-center items-center">
                    <div className="absolute top-0 left-2 text-[#A1A1A1] text-xl">Bill</div>
                    <div className="text-white text-[50px]">${billsTotal.toFixed(2)}</div>
                </div>
                <div className="w-full md:w-[1px] lg:w-[1px] h-[1px] md:h-full lg:h-full h-full bg-white top-0 bottom-0" />
                <div className="relative w-full md:w-2/3 lg:w-2/3 h-2/3 md:h-full lg:h-full flex flex-col justify-center items-start pl-8">
                    <div className="absolute top-0 left-2 md:left-8 lg:left-8 text-[#A1A1A1] text-xl">Breakdown</div>
                    <div className="mt-5 h-4/5 w-full overflow-y-scroll overflow-x-hidden">
                        {allBills.filter(x => x.cost > 0).length > 0 ? (
                            <div className="flex items-start flex-col w-full">
                            {allBills.map((x,i) => {
                                return (
                                    <div style={{ whiteSpace: 'pre-wrap' }} className="mx-auto w-full text-white text-md md:text-lg lg:text-lg">
                                        <div className="">{localStorage.campaigns[x.index].title}</div>
                                        <div className="text-[#A1A1A1]">    + ${x.cost.toFixed(2)}  ->   {x.days} active day{x.days > 1 ? "s" : ""} * ${x.payRate}</div>
                                        {x?.discount !== undefined && (
                                            <div className="text-[#A1A1A1]">    - ${x.discount}  ->   {x.discountRate} {x.discountType} ahead discount</div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        ) : (
                            <div className="flex items-start flex-col">
                                <div className="text-white text-lg" >Nothing to show</div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>
            <div className="w-full flex items-center flex-col text-white text-lg mb-2 font-bold mt-12 ">Payment Methods</div>
            <div className="mx-auto mb-10 w-[95%] md:w-[75%] lg:w-[75%] bg-lighterBackground rounded-xl drop-shadow-md py-8 px-2 md:px-8 lg:px-8 flex flex-col items-center justify-start">
                <div className="flex flex-col items-center w-full">
                    {localStorage.profile.cards.map((x,i) => {
                        return (
                            <div style={{ whiteSpace: 'pre-wrap' }} className="flex flex-row items-center w-[100%] md:w-[60%] lg:w-[60%] justify-between relative mb-5">
                                <input type="checkbox" className="w-[10px] h-[10px] absolute left-0" checked={defaultPayment === i ? true : false} onChange={() => {if (localStorage.profile.cards.length > 1) {setDefaultPayment(i); setConfirmationShowing(1);}}}/>
                                <div className="text-white text-md md:text-lg lg:text-lg ml-10 flex flex-row items-center">{x.card.brand} **{x.card.last4}<div className="text-[#A1A1A1] text-xs md:text-md lg:text-lg ml-2 md:ml-4 lg:ml-4">{(x.card.exp_month)}/{x.card.exp_year}</div></div>
                                {i == 0 && (
                                    <div className="text-[#A1A1A1] text-xs md:text-sm lg:text-sm">Default</div>
                                )}
                                {localStorage.profile.cards.length > 1 && (
                                    <button onClick={() => {setRemoveCard(i); setConfirmationShowing(2);}} className="p-1 md:p-2 lg:p-2 bg-background rounded-md drop-shadow-lg border border-background hover:border-white text-white text-sm md:text-md lg:text-md ">Remove</button>
                                )}
                            </div>
                        )
                    })}
                </div>
                <div className="w-[100%] h-[1px] bg-white mt-2 mb-2" />
                <div className="text-lg mt-2 mb-3 text-white mt-10">Add a new payment method.</div>
                    <Form onSubmit={() => {}} render={() => { 
                        return (
                        <form>
                            <div className="flex flex-row items-center justify-between w-full">
                            <Field
                                className="w-3/4 rounded-md drop-shadow-md p-3 mb-2 mt-2 mr-2"
                                name="number"
                                component="input"
                                type="text"
                                pattern="[\d| ]{16,22}"
                                placeholder="Card Number"
                                format={formatCreditCardNumber}
                                ref={cardNumberRef}
                            />
                            <Field
                                className="w-1/4 rounded-md drop-shadow-md p-3"
                                name="cvc"
                                component="input"
                                type="text"
                                pattern="\d{3,4}"
                                placeholder="CVC"
                                format={formatCVC}
                                ref={cvcRef}
                            />
                            </div>
                            <div className="flex flex-row items-center justify-between w-full mb-2">
                            <Field
                                className="w-1/4 rounded-md drop-shadow-md p-3 mr-2"
                                name="expiry"
                                component="input"
                                type="text"
                                pattern="\d\d/\d\d"
                                placeholder="Exp."
                                format={formatExpirationDate}
                                ref={expirationRef}
                            />

                            <Field
                                className="w-3/4 rounded-md drop-shadow-md p-3"
                                name="name"
                                component="input"
                                type="text"
                                placeholder="Name on Card"
                                ref={cardNameRef}
                            />
                            </div>
                        </form>
                        )}}>
                    </Form>
                    <button onClick={() => {addCardToAccount()}} className="mb-10 p-3 bg-primary text-lg text-background font-bold rounded-md mt-10">
                        {newCardLoading ? (
                            <Spinner />
                        ) : (
                            <div>Add Card</div>
                        )}
                        
                    </button>
            </div>
        </div>
    )
}
