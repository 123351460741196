import React from "react";
import { useState, useEffect } from 'react';
import "../styles/main.css";
import { useNavigate } from 'react-router-dom';
import { Dashboard } from "../Components/Dashboard";
import { Campaigns } from "../Components/Campaigns";
import { Billing } from "../Components/Billing";
import { Account } from "../Components/Account";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getProfile } from '../backend/database';
import { setProfile, setCampaigns } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { MdAttachMoney } from "react-icons/md";
import { CgProfile } from "react-icons/cg";

export const getCookie = (key) => {
    const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${key}=`))
    ?.split("=")[1];
    return cookieValue;
}

export const Home = () => {
    const [readyToLoad, setReadyToLoad] = useState(false);
    const [navItem, setNavItem] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();



    useEffect(() => {
        const cookieValue = getCookie("token");
        if (cookieValue !== undefined){
            const url = new URL(window.location.href);
            const path = url.pathname.split("/");
            switch(path[path.length - 1]) {
                case "home":
                    setNavItem(0);
                    break;
                case "campaigns":
                    setNavItem(1);
                    break; 
                case "billing":
                    setNavItem(2);
                    break;
                case "account":
                    setNavItem(3);
                    break;
            }
            setReadyToLoad(true);
            const userID = getCookie('id');
            getProfile(userID, cookieValue).then((response) => {
                if (response.profile !== undefined) {
                    dispatch(setProfile(response.profile));
                }
            });
        }else{
            navigate('/swapt/business');
        }
    },[]);

    const signOut = () => {
        document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; `; 
        document.cookie = `id=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; `; 
        navigate("/swapt/business");
        
    }

    return readyToLoad ? (
        <div className="relative w-full h-screen bg-background flex flex-row overflow-hidden">
            <div className={`z-30 drop-shadow-none border border-y-0 border-l-0 border-r-1 lg:border-transparent border-white w-2/3 lg:w-1/6 md:w-2/5 absolute transition-300 lg:static ${menuOpen ? "left-0" : "-left-2/3"} h-screen bg-background`}>
                <img alt="logo" className="h-16 w-16 ml-7 mt-7" src={"/assets/images/logo.png"} />
                <button onClick={() => {setMenuOpen(false)}} className="absolute opacity-100 lg:opacity-0 right-10 top-14">
                    <ArrowBackIosIcon className="text-secondary absolute" />
                </button>
                <div className="flex flex-col items-end mt-14">
                    <button onClick={() => {setNavItem(0); setMenuOpen(false);}} className={`hover:cursor-pointer hover:bg-lighterBackground flex flex-row ${navItem == 0 ? "bg-lighterBackground" : "bg-background"} w-11/12 h-12 rounded-md items-center`} >
                        <img alt="" className="h-8 w-8 ml-2 mr-5" src={"/assets/images/dashboard_white.png"} />
                        <p className="text-secondary font-bold text-lg">Dashboard</p>
                    </button>
                </div>
                <div className="flex flex-col items-end mt-5">
                    <button onClick={() => {setNavItem(1); setMenuOpen(false);}} className={`hover:cursor-pointer hover:bg-lighterBackground flex flex-row bg-${navItem == 1 ? "lighterBackground" : "background"} w-11/12 h-12 rounded-md items-center`} >
                        <img alt="" className="h-8 w-8 ml-2 mr-5" src={"/assets/images/campaigns_white.png"} />
                        <p className="text-secondary font-bold text-lg">Campaigns</p>
                    </button>
                </div>
                <div className="flex flex-col items-end mt-5">
                    <button onClick={() => {setNavItem(2); setMenuOpen(false);}} className={`hover:cursor-pointer hover:bg-lighterBackground flex flex-row bg-${navItem == 2 ? "lighterBackground" : "background"} w-11/12 h-12 rounded-md items-center`} >
                        <MdAttachMoney className="text-white h-8 w-8 ml-2 mr-5"/>
                        <p className="text-secondary font-bold text-lg">Billing</p>
                    </button>
                </div>
                <div className="flex flex-col items-end mt-5">
                    <button onClick={() => {setNavItem(3); setMenuOpen(false);}} className={`hover:cursor-pointer hover:bg-lighterBackground flex flex-row bg-${navItem == 3 ? "lighterBackground" : "background"} w-11/12 h-12 rounded-md items-center`} >
                        <CgProfile className="text-white h-8 w-8 ml-2 mr-5"/>
                        <p className="text-secondary font-bold text-lg">Account</p>
                    </button>
                </div>
                <button onClick={() => {signOut()}} className="absolute bottom-10 flex flex-col items-end w-full">
                    <p className="text-error font-bold text-md w-5/6 flex items-start">Sign Out</p>
                </button>
            </div>
            <button onClick={() => {setMenuOpen(true)}} className="z-20 static absolute h-8 opacity-100 lg:left-4 ml-4 mt-5 lg:opacity-0 ">
                <MenuIcon className="text-secondary text-2xl font-bold"/>
            </button>
            <div className="lg:w-5/6 md:w-full sm:w-full w-full h-full pl-0 overflow-hidden z-10">
                {navItem == 0 ? (
                    <Dashboard />
                ) : navItem == 1 ? (
                    <Campaigns />
                ) : navItem == 2 ? (
                    <Billing />
                ) : (
                    <Account />
                )}
            </div>
        </div>
    ) : (
        <div />
    )
}
