import React from "react";
import { useState, useEffect, useRef } from 'react';
import "../styles/main.css";
import { LocalDining } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import { getCampaignInteractions, getCampaigns } from "../backend/database"; 
import { setCampaigns, setInteractions } from "../redux/actions";
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { Spinner } from "react-activity";
import { getCookie } from "../Pages/Home";

export const Statistics = ({ interactions, campaigns }) => {

    const [loaded, setLoaded] = useState(false);
    const [xData, setXData] = useState([]);
    const [yData, setYData] = useState([]);
    const [leftSwipes, setLeftSwipes] = useState([]);
    const [rightSwipes, setRightSwipes] = useState([]);
    const [timePeriod, setTimePeriod] = useState(7);
    const [allBills, setBills] = useState([]);
    const [billsTotal, setTotal] = useState(0.00);
    const localStorage = useSelector(state => state.localStorage);
    const dispatch = useDispatch();
    const lineChartContainer = useRef();
    const pieChartContainer = useRef();


    useEffect(() => {

        //prep data for graph of interactions
        let newXData = [];
        let currentDate = new Date();
        currentDate.setHours(0,0,0,0);
        for (let i = 0; i < timePeriod; i++) {
            let newDate = new Date((currentDate.getTime() + (24 * 60 * 60 * 1000))  - ((timePeriod - i) * 24 * 60 * 60 * 1000));
            newDate.setHours(0,0,0,0);
            newXData.push(newDate);
        }
        setXData(newXData);
        let newYData = [];
        console.log('running through x data');
        console.log('length is:' + interactions.length);
        newXData.map((x) => {
            let interactionsFound = 0;
            interactions.map((i) => {
                let interactionTime = new Date(i.timestamp);
                interactionTime.setHours(0,0,0,0);
                console.log(interactionTime);
                console.log(x);
                if (interactionTime.getTime() === x.getTime()) {
                    interactionsFound += 1;
                }
            });
            newYData.push(interactionsFound);
        });
        setYData(newYData);

        //figure out right/left swipe data
        let tempLeftSwipes = [];
        let tempRightSwipes = [];
        interactions.map((x) =>{
            if (x.swipe === 'right') {
                tempRightSwipes.push(x);
            }else {
                tempLeftSwipes.push(x);
            }
        });
        setRightSwipes(tempRightSwipes);
        setLeftSwipes(tempLeftSwipes);

        //calculate bill
        let bills = [];
        let total = 0;
        campaigns.map((x,i) => {
            if (x.status === 'active') {
                let lastPaid = new Date(x.paidUntil);
                lastPaid.setHours(0,0,0,0);

                let days = (currentDate.getTime() - lastPaid.getTime()) / (24 * 60 * 60 * 1000);
                let payRate = x.package === 'basic' ? 2.99 : x.package === 'standard' ? 5.99 : 9.99;

                if (x.payAhead === "week") {
                    const calculation = (Math.ceil(days / 7) * payRate * 7 * 0.95);
                    bills.push(calculation);
                    total += calculation;
                }else if(x.payAhead === "month") {
                    const calculation = (Math.ceil(days / 30) * payRate * 30 * 0.9);
                    bills.push(calculation);
                    total += calculation;
                }else {
                    const calculation = (days * payRate);
                    bills.push(calculation);
                    total += calculation;
                }

            }else if (x.start.length !== 0){
                bills.push(0.0);
            }
        });
        setBills(bills);
        setTotal(total);
        setLoaded(true);
    },[interactions, campaigns, timePeriod])

    const getNextBill = () => {
        let today = new Date(); 
        let month = today.getMonth(); 
        return (`${(month+1 === 12 ? "01" : month+2)}/01/${month+1 === 12 ? today.getFullYear() + 1 : today.getFullYear()}`);
    }


    return (
        <div className="w-full overflow-x-hidden">
            {loaded ? (
                <div className="mt-5 w-full h-full flex items-center flex-col">
                    <div className="w-[95%] flex flex-row items-center justify-start">
                        <button onClick={() => {if (timePeriod !== 7) {setTimePeriod(7)}}} className={`text-white text-md pr-3 pl-3 ${timePeriod === 7 ? 'bg-lighterBackground border-lighterBackground' : 'bg-transparent border-transparent'} border rounded-md `}>
                            Week
                        </button>
                        <button onClick={() => {if (timePeriod !== 30) {setTimePeriod(30)}}} className={`text-white text-md pr-3 pl-3 ${timePeriod === 30 ? 'bg-lighterBackground border-lighterBackground' : 'bg-transparent border-transparent'} border rounded-md `}>
                            Month
                        </button>
                        <button onClick={() => {if (timePeriod !== 365) {setTimePeriod(365)}}}className={`text-white text-md pr-3 pl-3 ${timePeriod === 365 ? 'bg-lighterBackground border-lighterBackground' : 'bg-transparent border-transparent'} border rounded-md `}>
                            Year
                        </button>
                    </div>
                    <div ref={lineChartContainer} style={{ height: window.innerWidth < 736 ? window.innerHeight * 0.4 : window.innerHeight * 0.6 }} className={`w-[95%] bg-[#3A3939] rounded-lg mt-2 flex items-center overflow-hidden`}>
                            {loaded && (
                                <LineChart
                                    xAxis={
                                        [{scaleType: 'time', data: xData, tickInterval: xData, label: "Time Period", labelColor: 'white' }]
                                    }
                                    series={[
                                        {
                                        data: yData,
                                        },
                                    ]}
                                    yAxis={[
                                        {
                                            tickNumber: 3,
                                            label: "Interactions",
                                        }
                                    ]}

                                    width={lineChartContainer.current ? lineChartContainer.current.offsetWidth * 0.95 : window.innerWidth * 0.8}
                                    height={lineChartContainer.current ? lineChartContainer.current.offsetHeight * 0.95 : window.innerHeight * 0.4}
                                    sx={{
                                        "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                                            strokeWidth: "1",
                                            fill: "#ffffff"
                                        },
                                        "& .MuiChartsAxis-left .MuiChartsAxis-tick": {
                                            strokeWidth: "1",
                                            stroke: "#ffffff"
                                        },
                                        "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                                            strokeWidth: "1",
                                            stroke: "#ffffff"
                                        },
                                        "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                                            strokeWidth: "1",
                                            fill: "#ffffff"
                                        },
                                        "& .MuiChartsAxis-bottom .MuiChartsAxis-tick": {
                                            strokeWidth: "1",
                                            stroke: "#ffffff"
                                        },
                                        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                                            strokeWidth: "1",
                                            stroke: "#ffffff"
                                        }
                                    }}
                                />
                            )}
                    </div>
                    <div style={{ height: window.innerWidth < 736 ? window.innerHeight * 0.2 : window.innerHeight * 0.4 }} className={`mb-5 flex flex-row items-center flex-wrap justify-between w-[95%] mt-5`}>
                        <div ref={pieChartContainer} className="pt-3 relative rounded-lg overflow-hidden flex flex-col items-center justify-center h-full w-[47%] md:w-[47%] lg:w-[47%] bg-[#3A3939] overflow-hidden ">
                            <div className="text-white text-sm md:text-lg lg:text-lg absolute left-5 top-3">Swipes</div>
                           {rightSwipes.length === 0 && leftSwipes.length === 0 ? (
                                <div className="text-white text-lg mt-10">No data yet</div>
                            ) : (
                                <div className="">
                                <PieChart
                                    series={[
                                        {
                                        data: [
                                            { id: 0, value: rightSwipes.length, label: 'Right', color: 'turquoise' },
                                            { id: 1, value: leftSwipes.length, label: "Left", color: 'red' },
                                        ],
                                        arcLabel: (item) => `${parseInt(item.value / (interactions.length) * 100)}%`,
                                        arcLabelMinAngle: 30
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                        fill: '#222222',
                                        fontWeight: 'bold',
                                        },
                                    }}
                                    slotProps={{ 
                                        legend: {
                                            position: {
                                                vertical: 'middle',
                                                horizontal: 'right',
                                            },
                                            itemMarkWidth: 15,
                                            itemMarkHeight: 5,
                                            markGap: 5,
                                            itemGap: 10,
                                            hidden: false,
                                            fill: 'white',
                                            labelStyle: {
                                                fill: 'white',
                                                fontSize: 17,
                                                marginLeft: 5
                                            }
                                        }
                                    }}
                                    options={{
                                        center: [100,100]
                                    }}
                                    width={window.innerWidth > 1080 ? window.innerWidth * 0.35 : window.innerWidth * 0.4}
                                    height={window.innerWidth < 736 ? window.innerHeight * 0.2 : window.innerHeight * 0.30}
                                />
                                </div>
                            )}
                        </div>
                        <div className="pt-3 pb-3 relative rounded-lg overflow-hidden flex flex-col items-center justify-between h-full w-[47%] md:w-[47%] lg:w-[47%] bg-[#3A3939] overflow-hidden">
                            <div className="text-white text-sm md:text-lg lg:text-lg absolute left-5">Current bill</div>
                            <div className="w-1/3 h-full flex flex-col items-center justify-center">
                                <div className="w-full h-full flex flex-col items-center justify-center text-white text-[30px] md:text-[50px] lg:text-[50px] mt-10">${billsTotal.toFixed(2)}</div>
                            </div>
                            <div className="text-white text-sm md:text-lg lg:text-lg">Next payment: {getNextBill()}</div>
                        </div>

                    </div>
                </div>
            ) : (
                <Spinner size={'medium'} />
            )}

        </div>
    )
}
