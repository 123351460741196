import React from "react";
import { useState, useEffect, useRef } from 'react';
import "../styles/main.css";
import { LocalDining } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import { getAllCampaignInteractions, getCampaigns } from "../backend/database"; 
import { setCampaigns, setInteractions } from "../redux/actions";
import { LineChart } from '@mui/x-charts/LineChart';
import { Spinner } from "react-activity";
import { getCookie } from "../Pages/Home";
import { Statistics } from "./Statistics";

export const Dashboard = () => {

    const [loaded, setLoaded] = useState(false);
    const [allInteractions, setAllInteractions] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const localStorage = useSelector(state => state.localStorage);
    const dispatch = useDispatch();

    useEffect(() => {
        const userID = getCookie("id");
        let interactions = [];
        getAllCampaignInteractions(userID).then((response) => {
            if (response.interactions !== undefined) {
                dispatch(setInteractions(response.interactions));
                setAllInteractions(response.interactions);
                response.interactions.map((x) => {
                    interactions.push(x);
                });
            }else {
                alert(response.message);
            }
        });
        getCampaigns(userID).then((response) => {
            if (response.campaigns !== undefined) {
                dispatch(setCampaigns(response.campaigns));
                setAllCampaigns(response.campaigns);
            } else {
                alert(response.message);
            }
        });
        setLoaded(true);
    },[])


    return (
        <div className="w-full h-full flex items-center flex-col overflow-y-scroll overflow-x-hidden">
            <h1 className="text-secondary text-3xl font-bold mt-12">Hi, Welcome back</h1>
            <Statistics interactions={allInteractions} campaigns={allCampaigns} />
        </div>
    )
}
