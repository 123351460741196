import React from "react";
import "../styles/PrivacyPolicy.css";
import { TypeAnimation } from 'react-type-animation';

export const PrivacyPolicy = () => {

    return (
        <div className="privacyPolicy">
            <div className="container">
                <iframe src="https://drive.google.com/file/d/1xV00-h8FkNyCtkgHGi8_b_UsF-OOCdZ3/preview?usp=sharing" className="iframe" />
            </div>
        </div>
    );
}

