import React from "react";
import { useEffect, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { verifyAccount } from "../backend/database";
import { useSearchParams } from "react-router-dom";
import "../styles/styles.css";
import "../styles/AccountVerified.css";

function AccountVerified(){

    const [ searchParams ] = useSearchParams();
    const [accountVerified, setAccountVerified] = useState('');


    useEffect(() => {
        async function setup(){
            verifyAccount(searchParams.get('username')).then((response) => {
                if(response.message === 'success'){
                    setAccountVerified('success');
                }else{
                    setAccountVerified('server not responsive');
                }
            })
        }
        setup();
    },[]);

  return (
    <div className="accountVerified">
        <div className="overlay-container">
            <div className="App">
            <div className="container">
                {accountVerified === '' ? (
                    <h1>Verifying Account
                        <TypeAnimation
                            sequence={[
                                // Same substring at the start will only be typed out once, initially
                                '...',
                                100
                            ]}
                            wrapper="span"
                            speed={0}
                            style={{ fontSize: '1em', display: 'inline-block' }}
                            repeat={Infinity}
                            cursor={false}
                        />
                    </h1>
                ) : accountVerified !== 'success' ? (
                    <div>
                        <h1>Hmmm, something went wrong.</h1>
                        <br />
                        <br />
                        <br />
                        <br />
                        <h2>Please try again in a few minutes</h2>
                    </div>
                ) : (
                    <div>
                        <h1>Success!</h1>
                        <br />
                        <br />
                        <br />
                        <br />
                        <h2>You may now login to Swapt.</h2>
                    </div>
                )}
            </div>
            </div>
        </div>
    </div>
  );
}

export default AccountVerified;

