import React from "react";
import { useState, useEffect, useRef } from 'react';
import "../styles/styles.css";
import { Spinner } from "react-activity";
import { getCampaigns, initializeCampaign, createCampaignPayment, publishNewCampaign, getCampaignInteractions, pauseCampaign, deleteCampaign, resumeCampaign, createCustomer, addCard, deleteAccount, changeClientEmail, hash, changeClientPassword } from "../backend/database";
import { getCookie } from "../Pages/Home";
import { useNavigate } from 'react-router-dom';
import { setCampaigns, setProfile } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyExchangeTwoTone } from "@mui/icons-material";
import {APIProvider, AdvancedMarker, Map, Marker} from '@vis.gl/react-google-maps';
import { Circle } from './circle.tsx';
import { FaLock } from "react-icons/fa";
import { FaArrowRightLong, FaArrowLeftLong } from 'react-icons/fa6';
import { GiConfirmed } from "react-icons/gi";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdEmail, MdOutlineEdit } from "react-icons/md";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import { Form, Field } from "react-final-form";
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
  } from "./cardUtils";
import Styles from './Styles.js';
import { getStorage, ref, deleteObject } from "firebase/storage";
import { storage } from "../backend/firebaseConfig";
import { Statistics } from './Statistics';
import { IoIosClose } from "react-icons/io";

export const Account = () => {

    const [editingEmail, setEditingEmail] = useState(false);
    const [editingPassword, setEditingPassword] = useState(false);
    const [confirmationShowing, setConfirmationShowing] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const localStorage = useSelector(state => state.localStorage);
    const navigate = useNavigate();
    
    const dispatch = useDispatch();

    const emailInput = useRef();
    const passwordInput = useRef();
    const oldPasswordInput = useRef();

    const deleteAccountPressed = () => {
        deleteAccount(localStorage.profile._id).then((response) => {
            if (response.message === "success") {
                response.pics.map(async (x) => {
                    console.log('deleting');
                    let imageRef = ref(storage, x);
                    deleteObject(imageRef).then(() => {
                        console.log('file deleted');
                    }).catch((error) => {
                        console.log(`error occured: ${error}`);
                    }); 
                });
                document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; `; 
                document.cookie = `id=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; `; 
                navigate("/business/swapt");
            }else {
                alert(response.message);
            }
        })
    }   

    const validEmail = (x) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (emailRegex.test(x)) {
        return true;
        } else {
        return false;
        }
    }

    const saveChanges = async () => {
        if(editingEmail){
            if (validEmail(emailInput.current.value)) {
                setLoading(true);
                changeClientEmail(localStorage.profile._id, emailInput.current.value).then((response) => {
                    if (response.message === "success") {
                        dispatch(setProfile({...localStorage.profile, email: emailInput.current.value}));
                        setEditingEmail(false);
                        setLoading(false);
                    }else {
                        setErrorMessage(response.message);
                        setConfirmationShowing(4);
                        setLoading(false);
                    }
                })
            }else {
                setConfirmationShowing(3);
            }
        }else {
            const hashed = await hash(oldPasswordInput.current.value);
            if (hashed.hash !== localStorage.profile.password) {
                setConfirmationShowing(2);
            }else {
                setLoading(true);
                changeClientPassword(localStorage.profile._id, passwordInput.current.value).then((response) => {
                    if (response.message === "success") {
                        dispatch(setProfile({...localStorage.profile, password: hashed.hash}));
                        oldPasswordInput.current.value = "this is a fake password";
                        setEditingPassword(false);
                        setLoading(false);
                    }else {
                        setErrorMessage(response.message);
                        setConfirmationShowing(4);
                        setLoading(false);
                    }
                })
            }
        }

        
    }


    return (
        <div className="relative w-full h-screen overflow-y-scroll">
            {confirmationShowing > 0 && (
                <div className="w-full h-full flex items-center justify-center absolute top-0 z-20">
                    <div className="border-2 border-background p-6 bg-[#3a3939] z-10 absolute bottom-10 w-[500px] h-[500px] rounded-xl drop-shadow-2xl flex justify-center items-center flex-col relative">
                            <button onClick={() => {setConfirmationShowing(0)}} className="absolute top-2 right-2 text-md w-10 h-10 text-white"><IoIosClose size="small"/></button>
                            <div className="text-lg text-white text-center w-3/5">{confirmationShowing == 1 ? "Are you sure? This is permanent, and it cannot be undone. Your account will be charged whatever your outstanding bill is." : confirmationShowing === 2 ? "Your old password is incorrect. Please try again" : confirmationShowing === 3 ? "The email you provided is not valid. Please try again." : errorMessage}</div>
                            {confirmationShowing == 1 ? (
                                <button onClick={() => {setConfirmationShowing(0); deleteAccountPressed()}} className="mb-5 bg-red-500 p-3 text-md text-background mt-10 rounded-md">Delete</button>
                            ) : (
                                <button onClick={() => {setConfirmationShowing(0)}} className="mb-5 bg-primary p-3 text-md text-background mt-10 rounded-md">Ok</button>
                            )}
                            
                    </div> 
                </div>
            )}
            <div className="text-white text-lg w-full flex flex-row justify-center mt-20 mb-3 font-bold">Account Information</div>
            <div className="relative mx-auto w-[95%] md:w-[80%] lg:w-[80%] bg-lighterBackground rounded-xl drop-shadow-xl flex p-8 flex-col mb-10 flex flex-col items-center">
                <div className="w-[90%] md:w-[50%] lg:w-[50%] relative flex justify-center items-center">
                    <input defaultValue={localStorage.profile.email} ref={emailInput} disabled={editingEmail ? false : true} className={`${editingEmail ? "border border-b-1 border white border-l-0 border-r-0 border-t-0" : "border-none"} pl-10 md:pl-0 lg:pl-0 bg-lighterBackground text-white text-lg w-full text-center`} />
                    <MdEmail className="absolute left-2 text-xl text-white" />
                    {!editingEmail && (
                        <button className="absolute -right-10" onClick={() => {setEditingEmail(true)}}>
                            <MdOutlineEdit className="text-white text-lg"/>
                        </button>
                    )}

                </div>
                <div className="w-[90%] md:w-[50%] lg:w-[50%] relative flex justify-center items-center mt-8">
                    <input placeholder="Old Password" type="password" defaultValue={"this is a fake password"} ref={oldPasswordInput} disabled={editingPassword ? false : true} className={`${editingPassword ? "border border-b-1 border white border-l-0 border-r-0 border-t-0" : "border-none"} pl-10 md:pl-0 lg:pl-0 bg-lighterBackground text-white text-lg w-full text-center`} />
                    <RiLockPasswordLine className="absolute left-2 text-xl text-white" />
                    {!editingPassword && (
                        <button className="absolute -right-10" onClick={() => {setEditingPassword(true); oldPasswordInput.current.value = "";}}>
                            <MdOutlineEdit className="text-white text-lg"/>
                        </button>
                    )}

                </div>
                {editingPassword && (
                    <div className="w-[90%] md:w-[50%] lg:w-[50%] relative flex justify-center items-center mt-5">
                        <input placeholder="New Password" type="password" ref={passwordInput} className={`border border-b-1 border white border-l-0 border-r-0 border-t-0 pl-10 md:pl-0 lg:pl-0 bg-lighterBackground text-white text-lg w-full text-center`} />
                        <RiLockPasswordLine className="absolute left-2 text-xl text-white" />
                    </div>
                )}
                {(editingPassword || editingEmail) && (
                    <div className="w-full flex flex-row items-center justify-center mt-10">
                        <button onClick={() => {setEditingPassword(false); setEditingEmail(false); oldPasswordInput.current.value = "this is a fake password"; emailInput.current.value = localStorage.profile.email;}} className="p-3 bg-[#A1A1A1] rounded-lg mr-3">
                            Cancel
                        </button>
                        <button style={{ whiteSpace: 'pre-wrap' }} onClick={() => {saveChanges();}} className="p-3 bg-primary rounded-lg ml-3">
                            {loading ? (
                                <Spinner size="medium" />
                            ) : (
                                <div>  Save  </div>
                            )}
                            
                        </button>
                    </div>
                )}
            </div>
            <button className="p-3 bg-red-500 rounded-md mx-auto absolute bottom-5 left-[35%] md:left-[43%] lg:left-[43%]" onClick={() => {setConfirmationShowing(1)}}>Delete Account</button>
        </div>
    )
}
