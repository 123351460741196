export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const SET_PROFILE = "SET_PROFILE";
export const SET_INTERACTIONS = "SET_INTERACTIONS";

export const setCampaigns = campaigns => dispatch => {
    dispatch({
        type: SET_CAMPAIGNS,
        payload: campaigns
    });
};

export const setProfile = profile => dispatch => {
    dispatch({
        type: SET_PROFILE,
        payload: profile
    });
};


export const setInteractions = interactions => dispatch => {
    dispatch({
        type: SET_INTERACTIONS,
        payload: interactions
    });
};