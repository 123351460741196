import React from "react";
import "../styles/PrivacyPolicy.css";

export const TermsAndConditions = () => {

    return (
        <div className="privacyPolicy">
            <div className="container">
                <iframe src="https://drive.google.com/file/d/1-gmpzGeZ5WxSelh-8kU3G41jlMi1KOrG/preview?usp=sharing" className="iframe" />
            </div>
        </div>
    );
}

